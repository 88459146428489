
import Vue from "vue";
import dayjs from "dayjs";
import NavigationDrawer from "@/components/vuetify/NavigationDrawer.vue";
import ElementRangeDatePicker from "@/components/element/RangeDatePicker.vue";
import FilterChipButton from "@/components/vuetify/FilterChipButton.vue";
import {
  ActionStatus,
  ActionListSearchFilterDto,
  UpdateActionDto,
  UpdateSaleActionDetailDto,
  UpdateInterstoreTransferActionDetailDto,
} from "@/api/dto";
import RadialApiClient from "@/api/RadialApiClient";
import { Constant } from "@/store/constant";
import {
  ActionStatusDetail,
  ActionTypeDetail,
  ActionTableData,
  AdminUserDetail,
  ActionListProductDetail,
  ActionListSkuStockDetail,
  ActionListSkuCustomDetail,
  ActionListSkuInterstoreTranferDetail,
  ProductTrendTableHeader,
} from "@/store/model";
import {
  CustomActionMaster,
  ActionListSearchResultEntity,
  AdminUser,
  GetMainImagesEntity,
  Product,
  Season,
  Brand,
  SaleActionDetail,
  Sku,
  Store,
  Warehouse,
  Color,
  Size,
  ActionListCustomActionEntity,
  InterstoreTransferActionDetail,
  SkuDetailTrendEntity,
  DetailTrendItemEntity,
  S3KeySkuMainEntity,
  Category,
  FilterInfoDataEntity,
} from "@/api/entities";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import S3Image from "@/components/aws/S3Image.vue";
import Encoding from "encoding-japanese";
import Pagination from "@/components/vuetify/Pagination.vue";
import Loading from "@/components/vuetify/Loading.vue";
import store from "@/store";
import mixin from "@/mixin/mixin";

export interface SavedPrice {
  oldPrice: number | null;
  newPrice: number | null;
}

export interface ActionListSearchEntries {
  actionTypeDetails: ActionTypeDetail[];
  customActionMasters: CustomActionMaster[];
  creatorAdminUserDetails: AdminUserDetail[];
  managerAdminUserDetails: AdminUserDetail[];
  actionStatusDetails: ActionStatusDetail[];
}

export interface ActionListFilterInfo {
  actionTypeDetails: ActionTypeDetail[];
  customActionMasters: CustomActionMaster[];
  actionStatusDetails: ActionStatusDetail[];
}

export interface DataType {
  rangeDates: string[];
  rangeDatesMenu: boolean;
  today: Date;
  cancelDates: string[];
  isCanceled: boolean;
  filters: string[];
  isLoading: boolean;
  searchEntries: ActionListSearchEntries;
  actionListFilterInfo: ActionListFilterInfo;
  showingAlert: boolean;
  alertTitle: string;
  alertMessage: string;
  alertType: string;
  selectedActions: string[];
  actionOption: string;
  allActionTableDataList: ActionTableData[]; // テーブルに関する全データ
  hoverIndex: number | null;
  hoverProductSkuIndex: number | null;
  selectedIndex: number | null;
  titleFieldClicked: boolean;
  clickedPriceFieldIndex: number | null;
  showingManagerMenu: boolean;
  showingStatusMenu: boolean;
  adminUserDetails: AdminUserDetail[];
  changeStatusDetail: { index: number | null; oldStatus: ActionStatus | null };
  productImages: GetMainImagesEntity;
  products: Product[];
  seasons: Season[];
  brands: Brand[];
  savedPrice: SavedPrice;
  saleActionDetails: SaleActionDetail[];
  page: number;
  pageSize: number;
  listCount: number;
  actionPageStartIndex: number;
  actionPageEndIndex: number;
  productSkuPage: number;
  productSkuPageSize: number;
  productListCount: number;
  skuListCount: number;
  productSkuPageStartIndex: number;
  productSkuPageEndIndex: number;
  skus: Sku[];
  stores: Store[];
  warehouses: Warehouse[];
  colors: Color[];
  sizes: Size[];
  categories: Category[];
  selectedSkuInfo: ActionListSkuInterstoreTranferDetail | null; // 店間移動 - 欠品防止アラートでSKUの詳細情報を格納
  selectedProductInfo: Product | null; // 店間移動 - 欠品防止アラートでSKUの詳細情報(SKUに対応する商品の詳細情報)を格納
  stockAmountFieldClicked: boolean; // 店間移動 - 欠品防止アラートで移動在庫数のテキストフィールドがクリックされた時に立てるフラグ
  skuDetailTableHeader: ProductTrendTableHeader[];
  skuDetailTrendTableInfo: SkuDetailTrendEntity | null;
  skuImages: S3KeySkuMainEntity[] | null;
  productFilterStoreInput: FilterInfoDataEntity[]; // 集計対象外を「集計対象外」としてまとめた店舗一覧
  sourceStoreIdSearchEntries: FilterInfoDataEntity[];
  targetStoreIdSearchEntries: FilterInfoDataEntity[];
}

export default Vue.extend({
  name: "ActionList",
  mixins: [mixin],
  components: {
    NavigationDrawer,
    ElementRangeDatePicker,
    FilterChipButton,
    ConfirmationDialog,
    S3Image,
    Pagination,
    Loading,
  },
  data(): DataType {
    return {
      rangeDates: [dayjs().subtract(3, "month").format("YYYY-MM-DD"), dayjs().add(1, "month").format("YYYY-MM-DD")],
      rangeDatesMenu: false,
      today: new Date(),
      cancelDates: [],
      isCanceled: false,
      filters: ["アクション", "カスタムアクション", "作成者", "担当者", "ステータス"],
      isLoading: true,
      searchEntries: {
        actionTypeDetails: [],
        customActionMasters: [],
        creatorAdminUserDetails: [],
        managerAdminUserDetails: [],
        actionStatusDetails: [],
      },
      actionListFilterInfo: {
        actionTypeDetails: Constant.actionTypes,
        customActionMasters: [],
        actionStatusDetails: Constant.actionStatues,
      },
      showingAlert: false,
      alertTitle: "",
      alertMessage: "",
      alertType: "success",
      selectedActions: [],
      actionOption: "アクション",
      allActionTableDataList: [],
      hoverIndex: null,
      hoverProductSkuIndex: null,
      selectedIndex: null,
      titleFieldClicked: false,
      clickedPriceFieldIndex: null,
      showingManagerMenu: false,
      showingStatusMenu: false,
      adminUserDetails: [],
      changeStatusDetail: { index: null, oldStatus: null },
      productImages: { s3KeyProducts: [] },
      products: [],
      seasons: [],
      brands: [],
      savedPrice: { oldPrice: null, newPrice: null },
      saleActionDetails: [],
      page: 1,
      pageSize: 12,
      listCount: 0,
      actionPageStartIndex: 0,
      actionPageEndIndex: 0,
      productSkuPage: 1,
      productSkuPageSize: 20,
      productListCount: 0,
      skuListCount: 0,
      productSkuPageStartIndex: 0,
      productSkuPageEndIndex: 0,
      skus: [],
      stores: [],
      warehouses: [],
      colors: [],
      sizes: [],
      categories: [],
      selectedSkuInfo: null,
      selectedProductInfo: null,
      stockAmountFieldClicked: false,
      skuDetailTableHeader: Constant.productTrendTableHeader,
      skuDetailTrendTableInfo: null,
      skuImages: null,
      productFilterStoreInput: [],
      sourceStoreIdSearchEntries: [],
      targetStoreIdSearchEntries: [],
    };
  },
  props: {},
  computed: {
    minDate(): string {
      return dayjs(this.today).subtract(29, "day").format("YYYY-MM-DD");
    },
    actionOptions(): string[] {
      return this.selectedActions.length === 0 ? [] : ["アクション削除"];
    },
    editingIndex(): number | null {
      if (this.hoverIndex !== null) {
        return this.hoverIndex;
      } else if (this.selectedIndex !== null) {
        return this.selectedIndex;
      } else if (this.changeStatusDetail.index !== null) {
        return this.changeStatusDetail.index;
      } else {
        return null;
      }
    },
    actionTableDataList(): ActionTableData[] {
      // テーブルに表示する情報を含む配列
      return this.allActionTableDataList.slice(this.actionPageStartIndex, this.actionPageEndIndex);
    },
    paginatedProductSkuDetail():
      | ActionListProductDetail[]
      | ActionListCustomActionEntity[]
      | ActionListSkuStockDetail[]
      | ActionListSkuCustomDetail[]
      | ActionListSkuInterstoreTranferDetail[] {
      if (this.selectedIndex !== null) {
        if (this.actionTableDataList[this.selectedIndex].type === "SALE") {
          return this.actionTableDataList[this.selectedIndex].productDetails.slice(
            this.productSkuPageStartIndex,
            this.productSkuPageEndIndex
          );
        } else if (this.actionTableDataList[this.selectedIndex].type === "CUSTOM") {
          return this.actionTableDataList[this.selectedIndex].productSkuCustomDetails.slice(
            this.productSkuPageStartIndex,
            this.productSkuPageEndIndex
          );
        } else if (this.actionTableDataList[this.selectedIndex].type === "INTERSTORETRANSFER") {
          return this.actionTableDataList[this.selectedIndex].skuInterstoreTransferDetails
            .filter((detail) => {
              if (this.sourceStoreIdSearchEntries.length === 0) return true;
              return this.sourceStoreIdSearchEntries.map((entry) => entry.id).includes(detail.sourceStoreId);
            })
            .filter((detail) => {
              if (this.targetStoreIdSearchEntries.length === 0) return true;
              return this.targetStoreIdSearchEntries.map((entry) => entry.id).includes(detail.targetStoreId);
            })
            .slice(this.productSkuPageStartIndex, this.productSkuPageEndIndex);
        } else {
          return this.actionTableDataList[this.selectedIndex].skuStockDetails.slice(
            this.productSkuPageStartIndex,
            this.productSkuPageEndIndex
          );
        }
      } else {
        return [];
      }
    },
    skuDetailTableHeight(): string {
      if (this.skuDetailTrendTableInfo) {
        return this.skuDetailTrendTableInfo.items.length * 40 > window.innerHeight - 410
          ? (window.innerHeight - 410).toString()
          : "384";
      }
      return "384";
    },
  },
  methods: {
    async clearFilters() {
      this.searchEntries = {
        actionTypeDetails: [],
        customActionMasters: [],
        creatorAdminUserDetails: [],
        managerAdminUserDetails: [],
        actionStatusDetails: [],
      };
      await this.search();
      this.isLoading = false;
    },
    async search() {
      this.isLoading = true;
      const dto: ActionListSearchFilterDto = {
        start: dayjs(this.rangeDates[0]).startOf("day").toDate(),
        end: dayjs(this.rangeDates[1]).endOf("day").toDate(),
      };
      if (this.searchEntries.actionTypeDetails.length !== 0) {
        dto.actionTypes = this.searchEntries.actionTypeDetails.map((actionTypeDetail) => actionTypeDetail.key);
      }
      if (this.searchEntries.customActionMasters.length !== 0) {
        dto.customActionMasterIds = this.searchEntries.customActionMasters.map(
          (customActionMaster) => customActionMaster.id
        );
      }
      if (this.searchEntries.creatorAdminUserDetails.length !== 0) {
        dto.creatorAdminUserIds = this.searchEntries.creatorAdminUserDetails.map(
          (creatorAdminUserDetail) => creatorAdminUserDetail.id
        );
      }
      if (this.searchEntries.managerAdminUserDetails.length !== 0) {
        dto.managerAdminUserIds = this.searchEntries.managerAdminUserDetails.map(
          (managerAdminUserDetail) => managerAdminUserDetail.id
        );
      }
      if (this.searchEntries.actionStatusDetails.length !== 0) {
        dto.statuses = this.searchEntries.actionStatusDetails.map((actionStatusDetail) => actionStatusDetail.key);
      }
      // actionListの情報を取得
      const entities: ActionListSearchResultEntity[] = (await RadialApiClient.listActionList(dto)) ?? [];
      // 形式をコンバート
      this.allActionTableDataList = this.convertEntityToTable(entities);
      this.page = 1;
      this.listCount = this.allActionTableDataList.length; // paginationの設定
      this.updatePage(1);
      // リセット
      this.selectedActions = [];
      this.isLoading = false;
    },
    optionSelected() {
      this.showAlert("", "本当に選択したアクションを削除してよろしいですか？", "error");
    },
    showAlert(title: string, message: string, type: string) {
      this.alertTitle = title;
      this.alertMessage = message;
      this.alertType = type;
      this.showingAlert = true;
    },
    async deleteAction() {
      this.isLoading = true;
      await Promise.all(
        this.selectedActions.map(async (selectedAction) => {
          await RadialApiClient.deleteAction(selectedAction);
        })
      );
      this.selectedActions = [];
      this.$nextTick(async () => {
        await this.search();
        this.showingAlert = false;
        this.isLoading = false;
      });
    },
    convertCsv(csv: string, from: Encoding.Encoding, to: Encoding.Encoding): Uint8Array {
      const unicodeList: number[] = [];
      for (let i = 0; i < csv.length; i += 1) {
        unicodeList.push(csv.charCodeAt(i));
      }
      const shiftJisCodeList = Encoding.convert(unicodeList, to, from);
      return new Uint8Array(shiftJisCodeList);
    },
    downloadCsv(csv: string, filename: string) {
      const blob = new Blob([this.convertCsv(csv, "UNICODE", "SJIS")], {
        type: "csv/plain",
      });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = filename;
      link.click();
    },
    async downloadProductSkuListCsv(index: number | null) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      let csv = "";
      if (index !== null) {
        if (this.actionTableDataList[index].type === "SALE") {
          // actionTypeがPRODUCTのセールの場合
          // 以前に商品に関する情報を取得していない場合は取得する
          if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productDetails.length === 0) {
            const details =
              (await RadialApiClient.listProductsInSaleAction(this.actionTableDataList[index].actionId)) ?? [];
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productDetails = details.map(
              (detail) => {
                return {
                  oldSalesPrice: detail.oldSalesPrice?.toString() ?? "",
                  newSalesPrice: detail.newSalesPrice?.toString() ?? "",
                  productId: detail.product.id,
                  actionDetailId: detail.actionDetailId,
                };
              }
            );
            // productsに重複なく商品情報を格納
            const newDetails = details.filter(
              (detail) => !this.products.map((product) => product.id).includes(detail.product.id)
            );
            this.products = this.products.concat(newDetails.map((detail) => detail.product));
            const productIds = details.map((detail) => detail.product.id);
            const productImages = await RadialApiClient.getProductMainImages({ productIds });
            if (productImages) {
              this.productImages = {
                s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts),
              };
            }
          }

          const prductsInSelectedAction = this.products.filter((product) =>
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productDetails
              .map((detail) => detail.productId)
              .includes(product.id)
          );
          csv += Constant.productSaleActionCsvHeaders.map((x) => `"${x}"`).join(",") + "\n";
          for (const product of prductsInSelectedAction) {
            if (product !== null) {
              const list = [
                product.itemNumber ?? "",
                product.name ?? "",
                this.getSeasonName(product.seasonId ?? -1),
                this.getBrandName(product.brandId ?? -1),
                product.salesPrice?.toLocaleString() ?? "",
                product.salesStartedAt !== null ? this.convertDateFormat(product.salesStartedAt) : "",
                product.salesEndedAt !== null ? this.convertDateFormat(product.salesEndedAt) : "",
                Number(
                  this.allActionTableDataList[index + this.pageSize * (this.page - 1)].productDetails
                    .find((data) => data.productId === product.id)
                    ?.oldSalesPrice.replace(/[^0123456789]/g, "")
                ).toLocaleString(),
                Number(
                  this.allActionTableDataList[index + this.pageSize * (this.page - 1)].productDetails
                    .find((data) => data.productId === product.id)
                    ?.newSalesPrice.replace(/[^0123456789]/g, "")
                ).toLocaleString(),
              ];
              csv += list.map((item) => `"${item}"`).join() + "\n";
            } else {
              csv += ["", "", "", "", "", "", "", "", ""].map((item) => `"${item}"`).join() + "\n";
            }
          }
          this.downloadCsv(csv, `${this.actionTableDataList[index].title}.csv`);
        } else if (this.actionTableDataList[index].type === "CUSTOM") {
          // actionTypeがカスタムの場合
          // 以前に商品に関する情報を取得していない場合は取得する
          if (
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productSkuCustomDetails.length === 0
          ) {
            const details =
              (await RadialApiClient.listProductsSkusInCustomAction(this.actionTableDataList[index].actionId)) ?? [];
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productSkuCustomDetails = details.map(
              (detail) => {
                return {
                  actionDetailId: detail.actionDetailId,
                  skuId: detail.skuId,
                  productId: detail.productId,
                  itemNumber: detail.itemNumber,
                  productName: detail.productName,
                  janCode: detail.janCode ?? "-",
                  colorName: detail.colorName ?? "-",
                  sizeName: detail.sizeName ?? "-",
                  seasonName: detail.seasonName,
                  brandName: detail.brandName,
                  categoryName: detail.categoryName,
                  salesPrice: detail.salesPrice,
                  saleStartedAt: detail.saleStartedAt,
                  saleEndedAt: detail.saleEndedAt,
                };
              }
            );
            // 商品情報を格納
            const productIds = details.map((detail) => detail.productId);
            const productImages = await RadialApiClient.getProductMainImages({ productIds });
            if (productImages) {
              this.productImages = {
                s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts),
              };
            }
          }

          csv += Constant.productCustomActionCsvHeaders.map((x) => `"${x}"`).join(",") + "\n";
          for (const productSku of this.actionTableDataList[index].productSkuCustomDetails) {
            if (productSku !== null) {
              const list = [
                productSku.itemNumber,
                productSku.productName,
                productSku.janCode === "-" ? "" : productSku.janCode,
                productSku.colorName === "-" ? "" : productSku.colorName,
                productSku.sizeName === "-" ? "" : productSku.sizeName,
                productSku.seasonName,
                productSku.brandName,
                productSku.categoryName,
                productSku.salesPrice,
                dayjs(productSku.saleStartedAt).format("YYYY/MM/DD"),
                dayjs(productSku.saleEndedAt).format("YYYY/MM/DD"),
              ];
              csv += list.map((item) => `"${item}"`).join() + "\n";
            } else {
              csv += ["", "", "", "", "", "", "", "", ""].map((item) => `"${item}"`).join() + "\n";
            }
          }
          this.downloadCsv(csv, `${this.actionTableDataList[index].title}.csv`);
        } else if (this.actionTableDataList[index].type === "INTERSTORETRANSFER") {
          // actionTypeが店間移動 - 欠品防止の場合
          if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails.length === 0) {
            const details =
              (await RadialApiClient.listSkusInInterstoreTransferAction(this.actionTableDataList[index].actionId)) ??
              [];
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuInterstoreTransferDetails =
              details.map((detail) => {
                return {
                  itemNumber: detail.itemNumber,
                  productName: detail.productName,
                  colorId: detail.sku.colorId,
                  sizeId: detail.sku.sizeId,
                  productId: detail.sku.productId,
                  janCode: detail.sku.janCode ?? "",
                  sourceStoreId: detail.sourceStore?.id ?? "",
                  targetStoreId: detail.targetStore?.id ?? "",
                  sourceStoreStock: detail.sourceStoreStock?.quantity ?? 0,
                  targetStoreStock: detail.targetStoreStock?.quantity ?? 0,
                  stockAmount: detail.stockAmount,
                  skuId: detail.sku.id,
                  actionDetailId: detail.id,
                };
              });
            const skuIds = this.allActionTableDataList[
              (this.page - 1) * this.pageSize + index
            ].skuInterstoreTransferDetails.map((detail) => detail.skuId);
            this.skuImages = await RadialApiClient.getSkuMainImages({
              skuIds,
            });
            const productIds = details.map((detail) => detail.sku.productId);
            const productImages = await RadialApiClient.getProductMainImages({ productIds });
            if (productImages) {
              this.productImages = {
                s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts),
              };
            }
          }
          csv += Constant.skuInterstoreTransferActionCsvHeaders.map((x) => `"${x}"`).join(",") + "\n";
          for (const interstoreTransferSku of this.actionTableDataList[index].skuInterstoreTransferDetails
            .filter((detail) => {
              if (this.sourceStoreIdSearchEntries.length === 0) return true;
              return this.sourceStoreIdSearchEntries.map((entry) => entry.id).includes(detail.sourceStoreId);
            })
            .filter((detail) => {
              if (this.targetStoreIdSearchEntries.length === 0) return true;
              return this.targetStoreIdSearchEntries.map((entry) => entry.id).includes(detail.targetStoreId);
            })) {
            const list = [
              interstoreTransferSku.itemNumber,
              interstoreTransferSku.productName,
              interstoreTransferSku.janCode,
              this.getColorName(interstoreTransferSku.colorId),
              this.getSizeName(interstoreTransferSku.sizeId),
              this.getStoreName(interstoreTransferSku.sourceStoreId),
              interstoreTransferSku.sourceStoreStock,
              this.getStoreName(interstoreTransferSku.targetStoreId),
              interstoreTransferSku.targetStoreStock,
              interstoreTransferSku.stockAmount,
            ];
            csv += list.map((item) => `"${item}"`).join() + "\n";
          }
          this.downloadCsv(csv, `${this.actionTableDataList[index].title}.csv`);
        } else {
          // actionTypeが店間移動か在庫フォローの場合
          if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails.length === 0) {
            const details =
              (await RadialApiClient.listSkusInStockAction(this.actionTableDataList[index].actionId)) ?? [];
            this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails = details.map(
              (detail) => {
                return {
                  productName: detail.productName,
                  itemNumber: detail.itemNumber,
                  colorId: detail.sku.colorId,
                  sizeId: detail.sku.sizeId,
                  sku: detail.sku,
                  sourceStoreId: detail.sourceStoreId,
                  sourceWarehouseId: detail.sourceWarehouseId,
                  destinationStoreId: detail.destinationStoreId,
                  quantity: detail.quantity,
                  actionDetailId: detail.stockActionDetailId,
                };
              }
            );
            const productIds = details.map((detail) => detail.sku.productId);
            const productImages = await RadialApiClient.getProductMainImages({ productIds });
            if (productImages) {
              this.productImages = {
                s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts),
              };
            }
          }
          // csvのデータを成形
          let csv = Constant.skuStockActionCsvHeaders.map((x) => `"${x}"`).join(",") + "\n";
          for (const skuDetail of this.actionTableDataList[index].skuStockDetails) {
            const list = [
              skuDetail.itemNumber,
              skuDetail.productName,
              this.getColorName(skuDetail.colorId),
              this.getSizeName(skuDetail.sizeId),
              this.actionTableDataList[index].type === "STOCKMOVING"
                ? this.getStoreName(skuDetail.sourceStoreId ?? "")
                : this.getWarehouseName(skuDetail.sourceWarehouseId ?? ""),
              this.getStoreName(skuDetail.destinationStoreId),
              skuDetail.quantity,
            ];
            csv += list.map((item) => `"${item}"`).join() + "\n";
          }
          this.downloadCsv(csv, `${this.actionTableDataList[index].title}.csv`);
        }
      }
      this.isLoading = false;
    },
    async hoverTable(index: number, hoverFlag: number) {
      if (!this.showingManagerMenu && !this.showingStatusMenu) {
        if (hoverFlag === 0) {
          this.hoverIndex = index;
        } else if (hoverFlag === 1) {
          if (this.titleFieldClicked) {
            // hoverが切り替わるかつタイトルのテキストフィールドがクリックされていた時にタイトル変更のためにAPIを叩く
            await this.changeTitleField();
            this.titleFieldClicked = false;
          }
          this.hoverIndex = null;
        }
      }
    },
    async hoverProductTable(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hoverProductSkuIndex = index;
      } else if (hoverFlag === 1) {
        if (this.clickedPriceFieldIndex !== null) {
          // hoverが切り替わるかつセールアクションの価格がクリックされていた時に価格変更のためにAPIを叩く
          await this.changeNewPriceField(index);
        }
        this.clickedPriceFieldIndex = null;
        this.hoverProductSkuIndex = null;
      }
    },
    async hoverSkuTable(index: number, hoverFlag: number) {
      if (hoverFlag === 0) {
        this.hoverProductSkuIndex = index;
      } else if (hoverFlag === 1) {
        if (this.stockAmountFieldClicked) {
          // hoverが切り替わるかつ店間移動 - 欠品防止アクションの移動在庫数フィールドがクリックされていた時に移動在庫数変更のためにAPIを叩く
          await this.changeNewStockAmountField(index);
        }
        this.stockAmountFieldClicked = false;
        this.hoverProductSkuIndex = null;
      }
    },
    async updateTable() {
      // actionテーブルのデフォルトAPIを叩いてレコードを更新
      if (this.editingIndex !== null) {
        const editingActionTableData = this.actionTableDataList[this.editingIndex];
        const dto: UpdateActionDto = {
          title: editingActionTableData.title,
          managerAdminUserId: editingActionTableData.manager.id,
          status: editingActionTableData.status,
        };
        await RadialApiClient.updateAction(editingActionTableData.actionId, dto);
      }
    },
    getIcon(selectedAdminUserDetail: AdminUserDetail | undefined): string {
      if (selectedAdminUserDetail === undefined) {
        return "";
      } else {
        return (
          this.adminUserDetails.find((adminUserDetail) => {
            if (adminUserDetail !== null && selectedAdminUserDetail !== null) {
              return adminUserDetail.id === selectedAdminUserDetail.id;
            } else {
              return false;
            }
          })?.icon ?? ""
        );
      }
    },
    async showActionDetail(index: number) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;

      // openを押した商品一覧を表示
      // actionの種類によって商品かskuかで場合分け
      if (this.actionTableDataList[index].type === "SALE") {
        await this.updateProductSalePage(1, index);
        this.productListCount = this.actionTableDataList[index].productDetails.length;
      } else if (this.actionTableDataList[index].type === "CUSTOM") {
        await this.updateProductSkuCustomPage(1, index);
        this.productListCount = this.actionTableDataList[index].productSkuCustomDetails.length;
      } else if (this.actionTableDataList[index].type === "INTERSTORETRANSFER") {
        await this.updateSkuInterstoreTransferPage(1, index);
        this.skuListCount = this.actionTableDataList[index].skuStockDetails.length;
      } else {
        await this.updateSkuStockPage(1, index);
        this.skuListCount = this.actionTableDataList[index].skuStockDetails.length;
      }
      this.selectedIndex = index;
      this.isLoading = false;
    },
    clickTitleField() {
      this.titleFieldClicked = true;
    },
    async changeTitleField() {
      await this.updateTable();
    },
    listManagerActive() {
      this.showingManagerMenu = true;
    },
    listStatusActive() {
      this.showingStatusMenu = true;
    },
    adminUserMenuChanged(isActive: boolean) {
      this.showingManagerMenu = isActive;
      if (!this.showingManagerMenu) {
        //this.hoverIndex = null;
      }
    },
    statusMenuChanged(isActive: boolean) {
      this.showingStatusMenu = isActive;
      if (!this.showingStatusMenu) {
        // this.hoverIndex = null;
      }
    },
    async confirmToChangeStatus(index: number, status: ActionStatus) {
      if (this.actionTableDataList[index].status !== status) {
        if (this.editingIndex !== null) {
          if (this.actionTableDataList[this.editingIndex].type === "SALE") {
            this.changeStatusDetail.index = index;
            this.changeStatusDetail.oldStatus = this.actionTableDataList[index].status;
            this.actionTableDataList[index].status = status;
            this.hoverIndex = null;
            if (this.actionTableDataList[index].status === "PENDING") {
              // 新しいステータスで条件分岐している
              this.showAlert(
                "",
                `ステータスを更新しました!
            合わせて、radial上の商品価格を新価格から旧価格へ戻しますか？`,
                "info"
              );
            } else {
              this.showAlert(
                "",
                `ステータスを更新しました!
            合わせて、radial上の商品価格を旧価格から新価格へ更新しますか？`,
                "info"
              );
            }
          } else {
            this.actionTableDataList[index].status = status;
            await this.updateTable();
            this.showingStatusMenu = false;
          }
        }
      }
    },
    async changeStatus() {
      this.isLoading = true;
      if (this.changeStatusDetail.index !== null && this.changeStatusDetail.oldStatus !== null) {
        await this.updateTable();
        if (this.editingIndex !== null) {
          await RadialApiClient.updatePriceInActionList(this.actionTableDataList[this.editingIndex].actionId);
        }
        this.changeStatusDetail.index = null;
        this.changeStatusDetail.oldStatus = null;
      }
      this.isLoading = false;
      this.showingAlert = false;
      this.showingStatusMenu = false;
    },
    convertEntityToTable(entities: ActionListSearchResultEntity[]): ActionTableData[] {
      return entities
        .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())
        .map((entity) => {
          return {
            actionId: entity.actionId,
            title: entity.title,
            type: entity.type,
            customAction: entity.customActionMasterId ? this.getCustomAction(entity.customActionMasterId) : null,
            createdAt: dayjs(entity.createdAt).format("YYYY/MM/DD"),
            creator: this.getAdminUser(entity.creatorAdminUserId),
            manager: this.getAdminUser(entity.managerAdminUserId),
            status: entity.status,
            productDetails: [],
            skuStockDetails: [],
            productSkuCustomDetails: [],
            skuInterstoreTransferDetails: [],
            executedDate: entity.executedDate ? dayjs(entity.executedDate).format("YYYY/MM/DD") : "-",
          };
        });
    },
    getCustomAction(id: string): CustomActionMaster | null {
      return this.actionListFilterInfo.customActionMasters.find((customAction) => customAction.id === id) ?? null;
    },
    getAdminUser(id: string | null): AdminUserDetail {
      return (
        this.adminUserDetails.find((adminUserDetail) => adminUserDetail.id === id) ?? { id: "", icon: "", name: "" }
      );
    },
    async changeManager(index: number, adminUserDetail: AdminUserDetail) {
      if (this.actionTableDataList[index].manager.id !== adminUserDetail.id) {
        this.actionTableDataList[index].manager = adminUserDetail;
        await this.updateTable();
      }
      this.showingManagerMenu = false;
      this.hoverIndex = null;
    },
    getProductS3Key(productId: string): string {
      return this.productImages.s3KeyProducts.find((item) => item.productId === productId)?.s3Key ?? "";
    },
    getSkuS3Key(skuId: string): string {
      return this.skuImages?.find((item) => item.skuId === skuId)?.s3Key ?? "";
    },
    getProduct(productId: string): Product | null {
      if (this.products.find((product) => product.id === productId)) {
        return this.products.find((product) => product.id === productId) ?? null;
      } else {
        return null;
      }
    },
    getSeasonName(seasonId: number | null): string {
      if (seasonId === null) return "";
      return this.seasons.find((season) => season.id === seasonId)?.name ?? "No Data";
    },
    getBrandName(brandId: number | null): string {
      if (brandId === null) return "";
      return this.brands.find((brand) => brand.id === brandId)?.name ?? "No Data";
    },
    getCategoryName(categoryId: number | null): string {
      if (categoryId === null) return "";
      return this.categories.find((category) => category.id === categoryId)?.name ?? "No Data";
    },
    getSalesPrice(product: Product | null): string {
      return product?.salesPrice != null ? `￥${product.salesPrice.toLocaleString()}` : "-";
    },
    convertDateFormat(date: Date | null): string {
      if (date === null) {
        return "";
      } else {
        return dayjs(date).format("YYYY/MM/DD");
      }
    },
    getOffRatio(details: ActionListProductDetail[]): string {
      const oldPrices = details
        .map((detail) => Number(detail.oldSalesPrice.replace(/[^0123456789]/g, "")))
        .reduce(function (sum, element) {
          return (sum ?? 0) + (element ?? 0);
        }, 0);
      const newPrices = details
        .map((detail) => Number(detail.newSalesPrice.replace(/[^0123456789]/g, "")))
        .reduce(function (sum, element) {
          return (sum ?? 0) + (element ?? 0);
        }, 0);
      return oldPrices !== 0 ? Math.round((1 - (newPrices ?? 0) / (oldPrices ?? 0)) * 100).toString() : "0";
    },
    showDetailInNewTab(productId: string) {
      const routeData = this.$router.resolve({
        name: this.isAdmin ? "AdminProductDetail" : "ProductDetail",
        params: { productId },
      });
      window.open(routeData.href, "_blank");
    },
    zenkakuToHankaku(str: string): string {
      return str.replace(/[Ａ-Ｚａ-ｚ０-９．]/g, function (s) {
        return String.fromCharCode(s.charCodeAt(0) - 0xfee0);
      });
    },
    clickNewPriceField(index: number) {
      this.clickedPriceFieldIndex = index;
      if (this.selectedIndex !== null) {
        this.actionTableDataList[this.selectedIndex].productDetails[index].newSalesPrice = this.actionTableDataList[
          this.selectedIndex
        ].productDetails[index].newSalesPrice.replace(/[^0123456789]/g, "");
        this.savedPrice.newPrice = Number(
          this.actionTableDataList[this.selectedIndex].productDetails[index].newSalesPrice.replace(/[^0123456789]/g, "")
        );
      }
    },
    async changeNewPriceField(index: number) {
      if (this.selectedIndex !== null && index === this.clickedPriceFieldIndex) {
        const newPriceText = this.zenkakuToHankaku(
          this.actionTableDataList[this.selectedIndex].productDetails[index].newSalesPrice ?? "0"
        );
        const newPriceTextNoComma = newPriceText.replace(/,/g, "");
        const newPrice = !newPriceTextNoComma
          ? "0" // 空文字列の時
          : isNaN(Number(newPriceTextNoComma))
          ? this.savedPrice.newPrice?.toLocaleString() ?? "0" // 文字でない時
          : Number(newPriceTextNoComma) >= 1000000
          ? "1,000,000"
          : Number(newPriceTextNoComma) >= 0
          ? Number(newPriceTextNoComma).toLocaleString() // 数字が0以上の時
          : "0"; // 数字が0未満の時
        this.actionTableDataList[this.selectedIndex].productDetails[index].newSalesPrice = `￥${newPrice}`;
        await this.updatePrice(this.selectedIndex, index);
      }
    },
    async updatePrice(selectedIndex: number, productTableIndex: number) {
      const dto: UpdateSaleActionDetailDto = {
        oldSalesPrice: Number(
          this.actionTableDataList[selectedIndex].productDetails[productTableIndex].oldSalesPrice.replace(
            /[^0123456789]/g,
            ""
          )
        ),
        newSalesPrice: Number(
          this.actionTableDataList[selectedIndex].productDetails[productTableIndex].newSalesPrice.replace(
            /[^0123456789]/g,
            ""
          )
        ),
      };
      const id: string | undefined = this.saleActionDetails
        .filter((saleActionDetail) => saleActionDetail.actionId === this.actionTableDataList[selectedIndex].actionId)
        .find(
          (saleActionDetail) =>
            saleActionDetail.productId ===
            this.actionTableDataList[selectedIndex].productDetails[productTableIndex].productId
        )?.id;
      if (id !== undefined) {
        await RadialApiClient.updateSaleActionDetail(id ?? "", dto);
      }
    },
    async changeDateRange() {
      this.isCanceled = false;
      this.rangeDatesMenu = false;
      this.selectedIndex = null;
      await this.search();
    },
    updatePage(page: number) {
      this.actionPageStartIndex = (page - 1) * this.pageSize;
      this.actionPageEndIndex = page * this.pageSize;
      this.selectedActions = [];
      this.page = page;
    },
    async updateProductSalePage(page: number, index: number) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      // 以前に商品に関する情報を取得していない場合は取得する
      if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productDetails.length === 0) {
        const details =
          (await RadialApiClient.listProductsInSaleAction(this.actionTableDataList[index].actionId)) ?? [];
        this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productDetails = details.map((detail) => {
          return {
            oldSalesPrice: detail.oldSalesPrice?.toString() ?? "",
            newSalesPrice: detail.newSalesPrice?.toString() ?? "",
            productId: detail.product.id,
            actionDetailId: detail.actionDetailId,
          };
        });
        // productsに重複なく商品情報を格納
        const newDetails = details.filter(
          (detail) => !this.products.map((product) => product.id).includes(detail.product.id)
        );
        this.products = this.products.concat(newDetails.map((detail) => detail.product));
        const productIds = details.map((detail) => detail.product.id);
        const productImages = await RadialApiClient.getProductMainImages({ productIds });
        if (productImages) {
          this.productImages = { s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts) };
        }
      }
      this.productSkuPageStartIndex = (page - 1) * this.productSkuPageSize;
      this.productSkuPageEndIndex = page * this.productSkuPageSize;
      this.productSkuPage = page;

      this.isLoading = false;
    },
    async updateProductSkuCustomPage(page: number, index: number) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      // 以前にskuに関する情報を取得していない場合は取得する
      if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productSkuCustomDetails.length === 0) {
        const details =
          (await RadialApiClient.listProductsSkusInCustomAction(this.actionTableDataList[index].actionId)) ?? [];
        this.allActionTableDataList[(this.page - 1) * this.pageSize + index].productSkuCustomDetails = details.map(
          (detail) => {
            return {
              actionDetailId: detail.actionDetailId,
              skuId: detail.skuId,
              productId: detail.productId,
              itemNumber: detail.itemNumber,
              productName: detail.productName,
              janCode: detail.janCode ?? "-",
              colorName: detail.colorName ?? "-",
              sizeName: detail.sizeName ?? "-",
              seasonName: detail.seasonName,
              brandName: detail.brandName,
              categoryName: detail.categoryName,
              salesPrice: detail.salesPrice,
              saleStartedAt: detail.saleStartedAt,
              saleEndedAt: detail.saleEndedAt,
            };
          }
        );
        const productIds = details.map((detail) => detail.productId);
        const productImages = await RadialApiClient.getProductMainImages({ productIds });
        if (productImages) {
          this.productImages = { s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts) };
        }
      }
      this.productSkuPageStartIndex = (page - 1) * this.productSkuPageSize;
      this.productSkuPageEndIndex = page * this.productSkuPageSize;
      this.productSkuPage = page;
      this.isLoading = false;
    },
    async updateSkuStockPage(page: number, index: number) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      // 以前にskuに関する情報を取得していない場合は取得する
      if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails.length === 0) {
        const details = (await RadialApiClient.listSkusInStockAction(this.actionTableDataList[index].actionId)) ?? [];
        this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails = details.map((detail) => {
          return {
            productName: detail.productName,
            itemNumber: detail.itemNumber,
            colorId: detail.sku.colorId,
            sizeId: detail.sku.sizeId,
            sku: detail.sku,
            sourceStoreId: detail.sourceStoreId,
            sourceWarehouseId: detail.sourceWarehouseId,
            destinationStoreId: detail.destinationStoreId,
            quantity: detail.quantity,
            actionDetailId: detail.stockActionDetailId,
          };
        });
        const productIds = details.map((detail) => detail.sku.productId);
        const productImages = await RadialApiClient.getProductMainImages({ productIds });
        if (productImages) {
          this.productImages = { s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts) };
        }
      }
      this.productSkuPageStartIndex = (page - 1) * this.productSkuPageSize;
      this.productSkuPageEndIndex = page * this.productSkuPageSize;
      this.productSkuPage = page;
      this.isLoading = false;
    },
    async updateSkuInterstoreTransferPage(page: number, index: number) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      // 以前にskuに関する情報を取得していない場合は取得する
      if (this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuStockDetails.length === 0) {
        const details =
          (await RadialApiClient.listSkusInInterstoreTransferAction(this.actionTableDataList[index].actionId)) ?? [];
        this.allActionTableDataList[(this.page - 1) * this.pageSize + index].skuInterstoreTransferDetails = details.map(
          (detail) => {
            return {
              itemNumber: detail.itemNumber,
              productName: detail.productName,
              colorId: detail.sku.colorId,
              sizeId: detail.sku.sizeId,
              productId: detail.sku.productId,
              janCode: detail.sku.janCode ?? "",
              sourceStoreId: detail.sourceStore?.id ?? "",
              targetStoreId: detail.targetStore?.id ?? "",
              sourceStoreStock: detail.sourceStoreStock?.quantity ?? 0,
              targetStoreStock: detail.targetStoreStock?.quantity ?? 0,
              stockAmount: detail.stockAmount,
              skuId: detail.sku.id,
              actionDetailId: detail.id,
            };
          }
        );
        const skuIds = this.allActionTableDataList[
          (this.page - 1) * this.pageSize + index
        ].skuInterstoreTransferDetails.map((detail) => detail.skuId);
        this.skuImages = await RadialApiClient.getSkuMainImages({
          skuIds,
        });
        const productIds = details.map((detail) => detail.sku.productId);
        const productImages = await RadialApiClient.getProductMainImages({ productIds });
        if (productImages) {
          this.productImages = { s3KeyProducts: this.productImages.s3KeyProducts.concat(productImages.s3KeyProducts) };
        }
      }
      this.productSkuPageStartIndex = (page - 1) * this.productSkuPageSize;
      this.productSkuPageEndIndex = page * this.productSkuPageSize;
      this.productSkuPage = page;
      this.isLoading = false;
    },
    clickNewStockAmountField() {
      this.stockAmountFieldClicked = true;
    },
    async changeNewStockAmountField(index: number) {
      if (this.selectedIndex === null) return;
      const newStockAmountText = this.zenkakuToHankaku(
        String(this.actionTableDataList[this.selectedIndex].skuInterstoreTransferDetails[index].stockAmount)
      );
      const newStockAmount = !newStockAmountText
        ? 0 // 空文字列の時
        : isNaN(Number(newStockAmountText))
        ? 0 // 文字でない時
        : Number(newStockAmountText);
      const dto: UpdateInterstoreTransferActionDetailDto = {
        stockAmount: newStockAmount,
      };
      const id = this.actionTableDataList[this.selectedIndex].skuInterstoreTransferDetails[index].actionDetailId;
      await RadialApiClient.updateInterstoreTransferDetail(id, dto);
    },
    cancelToChangeStatus() {
      this.showingAlert = false;
      this.showingManagerMenu = false;
      this.showingStatusMenu = false;
    },
    getActionChipName(data: ActionTableData): string {
      switch (data.type) {
        case "SALE":
          return "セール";
        case "CUSTOM":
          return data.customAction?.name ?? "";
        case "STOCKMOVING":
          return "店間移動";
        case "STOCKFOLLOW":
          return "在庫フォロー";
        case "INTERSTORETRANSFER":
          return "店間移動 - 欠品防止";
        default:
          return "";
      }
    },
    getColorName(colorId: string): string {
      return this.colors.find((color) => color.id === colorId)?.name ?? "";
    },
    getSizeName(sizeId: string): string {
      return this.sizes.find((size) => size.id === sizeId)?.name ?? "";
    },
    getStoreName(storeId: string): string {
      return this.stores.find((store) => store.id === storeId)?.name ?? "";
    },
    getWarehouseName(warehouseId: string): string {
      return this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name ?? "";
    },
    async deleteProductSku(type: string, actionDetailId: string) {
      this.isLoading = true;
      // APIを叩いてレコードを削除
      if (this.selectedIndex !== null) {
        switch (type) {
          case "SALE":
            await RadialApiClient.deleteSaleActionDetail(actionDetailId);
            break;
          case "CUSTOM":
            await RadialApiClient.deleteCustomActionDetail(actionDetailId);
            break;
          case "STOCK":
            await RadialApiClient.deleteStockActionDetail(actionDetailId);
            break;
          case "INTERSTORETRANSFER":
            await RadialApiClient.deleteInterstoreTransferDetail(actionDetailId);
            break;
          default:
            break;
        }
        // 削除した商品SKU情報のテーブル情報を削除
        switch (type) {
          case "SALE":
            const detailProductIndex = this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].productDetails.findIndex((detail) => detail.actionDetailId === actionDetailId);
            this.allActionTableDataList[(this.page - 1) * this.pageSize + this.selectedIndex].productDetails.splice(
              detailProductIndex,
              1
            );
            this.productListCount =
              this.allActionTableDataList[(this.page - 1) * this.pageSize + this.selectedIndex].productDetails.length;
            break;
          case "CUSTOM":
            const detailCustomSkuIndex = this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].productSkuCustomDetails.findIndex((detail) => detail.actionDetailId === actionDetailId);
            this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].productSkuCustomDetails.splice(detailCustomSkuIndex, 1);
            this.productListCount =
              this.allActionTableDataList[
                (this.page - 1) * this.pageSize + this.selectedIndex
              ].productSkuCustomDetails.length;
            break;
          case "STOCK":
            const detailStockSkuIndex = this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].skuStockDetails.findIndex((skuDetail) => skuDetail.actionDetailId === actionDetailId);
            this.allActionTableDataList[(this.page - 1) * this.pageSize + this.selectedIndex].skuStockDetails.splice(
              detailStockSkuIndex,
              1
            );
            this.skuListCount =
              this.allActionTableDataList[(this.page - 1) * this.pageSize + this.selectedIndex].skuStockDetails.length;
            break;
          case "INTERSTORETRANSFER":
            const detailInterstoreTransferSkuIndex = this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].skuInterstoreTransferDetails.findIndex((skuDetail) => skuDetail.actionDetailId === actionDetailId);
            this.allActionTableDataList[
              (this.page - 1) * this.pageSize + this.selectedIndex
            ].skuInterstoreTransferDetails.splice(detailInterstoreTransferSkuIndex, 1);
            this.skuListCount =
              this.allActionTableDataList[
                (this.page - 1) * this.pageSize + this.selectedIndex
              ].skuInterstoreTransferDetails.length;
            break;
        }
      }
      this.isLoading = false;
    },
    async showSkuDetail(skuDetail: ActionListSkuInterstoreTranferDetail) {
      // indexはpaginationで分けられたページ内のindex
      this.isLoading = true;
      this.selectedSkuInfo = skuDetail;
      this.selectedProductInfo = await RadialApiClient.getProduct(skuDetail.productId);
      this.skuDetailTrendTableInfo = await RadialApiClient.getSkuDetailTrends(skuDetail.skuId);
      this.isLoading = false;
    },
    getServiceLogo(storeId: string, warehouseId: string): string {
      const serviceId =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.serviceId
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.serviceId;
      switch (serviceId) {
        case 0:
          return require("@/assets/smaregi-logo.svg");
        case 1:
          return require("@/assets/shopify.svg");
        case 100:
          return require("@/assets/futureshop.svg");
        case 101:
          return require("@/assets/shopify.svg");
        case 103:
          return require("@/assets/base.svg");
        case 200:
          return require("@/assets/logizard.png");
        default:
          return require("@/assets/customServiceLogo.svg");
      }
    },
    getChanelName(storeId: string | null, warehouseId: string | null): string {
      const chanelName =
        warehouseId === null
          ? this.stores.find((store) => store.id === storeId)?.name
          : this.warehouses.find((warehouse) => warehouse.id === warehouseId)?.name;
      return chanelName ?? "";
    },
    // 商品詳細テーブル関係はProductDetailTrend.vueのコピペ
    customSort(items: DetailTrendItemEntity[], index: string[], isDesc: boolean[]) {
      const ascValue = isDesc[0] ? -1 : 1;
      items.sort((a, b) => {
        switch (index[0]) {
          case "storageLife":
            if (a.storageLife === "∞週") return ascValue;
            if (b.storageLife === "∞週") return -ascValue;
            return (parseInt(a.storageLife) - parseInt(b.storageLife)) * ascValue;
          case "digestionRate":
            if (a.digestionRate === "-") return -ascValue;
            if (b.digestionRate === "-") return ascValue;
            return (parseInt(a.digestionRate) - parseInt(b.digestionRate)) * ascValue;
          case "stockCount":
            return (a.stockCount - b.stockCount) * ascValue;
          case "unitPrice":
            return (a.salesCounts[0] - b.salesCounts[0]) * ascValue;

          default:
            return 0;
        }
      });
      return items;
    },
  },
  watch: {
    rangeDatesMenu: {
      async handler() {
        if (this.rangeDatesMenu) {
          this.cancelDates = this.rangeDates;
          this.isCanceled = true;
        } else if (!this.rangeDatesMenu && this.isCanceled) {
          this.rangeDates = this.cancelDates;
        }
      },
    },
    showingAlert: {
      handler() {
        if (!this.showingAlert) {
          this.actionOption = "アクション";
        }
      },
    },
  },
  async mounted() {
    // 作成者と担当者のリスト情報を取得
    if (store.state.company) {
      this.adminUserDetails.push({
        id: store.state.company.id,
        icon: store.state.company.icon ?? "",
        name: store.state.company.name,
      });
    }
    await Promise.all([
      (async () => {
        this.stores = (await RadialApiClient.listStores()) ?? [];
      })(),
      (async () => {
        this.warehouses = (await RadialApiClient.listWarehouses()) ?? [];
      })(),
      (async () => {
        this.colors = (await RadialApiClient.listColors()) ?? [];
      })(),
      (async () => {
        this.sizes = (await RadialApiClient.listSizes()) ?? [];
      })(),
    ]);
    const adminUsers: AdminUser[] = (await RadialApiClient.listAdminUsers()) ?? [];
    adminUsers.map((adminUser) => {
      if (adminUser.adminUserMasterId !== 3) {
        this.adminUserDetails.push({ id: adminUser.id, icon: adminUser.icon ?? "", name: adminUser.name });
      }
    });

    // seasonとbrandとcategoryのテーブルを取得
    this.seasons = (await RadialApiClient.listSeasons()) ?? [];
    this.brands = (await RadialApiClient.listBrands()) ?? [];
    this.categories = (await RadialApiClient.listCategories()) ?? [];
    this.saleActionDetails = (await RadialApiClient.listSaleActionDetails()) ?? [];

    // 店舗一覧を作成
    const storeTable = (await RadialApiClient.listStores()) ?? [];
    storeTable.map((store) => {
      if (store.operating) {
        this.productFilterStoreInput.push({ id: store.id, name: store.name, code: null });
      }
    });

    // custom action masterのテーブルを取得
    this.actionListFilterInfo.customActionMasters = (await RadialApiClient.listCustomActionMasters()) ?? [];

    // actionのテーブルを取得
    const dto: ActionListSearchFilterDto = {
      start: dayjs(this.rangeDates[0]).startOf("day").toDate(),
      end: dayjs(this.rangeDates[1]).endOf("day").toDate(),
    };
    const entities: ActionListSearchResultEntity[] = (await RadialApiClient.listActionList(dto)) ?? [];
    this.allActionTableDataList = this.convertEntityToTable(entities);
    this.listCount = this.allActionTableDataList.length; // paginationの設定
    this.updatePage(1);

    // ページを開いたときにopenしているアクションを指定
    const actionId = this.$route.params.actionId;
    if (actionId) {
      let index = this.allActionTableDataList.map((action) => action.actionId).indexOf(actionId);
      if (index !== -1) {
        this.updatePage(Math.floor(index / this.pageSize) + 1);
        await this.showActionDetail(index % this.pageSize);
      } else {
        // 1週間以内に指定のアクションがないため、1年前〜1年後で検索しなおし探す
        this.rangeDates = [
          dayjs().subtract(12, "month").format("YYYY-MM-DD"),
          dayjs().add(12, "month").format("YYYY-MM-DD"),
        ];
        // actionのテーブルを取得
        await this.search();
        index = this.allActionTableDataList.map((action) => action.actionId).indexOf(actionId);
        if (index !== -1) {
          this.updatePage(Math.floor(index / this.pageSize) + 1);
          await this.showActionDetail(index % this.pageSize);
        }
      }
    }

    this.isLoading = false;
  },
});
