import { render, staticRenderFns } from "./AlertSetting.vue?vue&type=template&id=688f0a8e&scoped=true&"
import script from "./AlertSetting.vue?vue&type=script&lang=ts&"
export * from "./AlertSetting.vue?vue&type=script&lang=ts&"
import style0 from "./AlertSetting.vue?vue&type=style&index=0&id=688f0a8e&prod&scoped=true&lang=css&"
import style1 from "./AlertSetting.vue?vue&type=style&index=1&id=688f0a8e&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "688f0a8e",
  null
  
)

export default component.exports