import { OrderProductStockedStatus, RadialConnection, ShopifyJanCodeRecognitionKey } from "@/api/entities";
import { ResultDownloadProductMasterListType, ResultDownloadSkuMasterListType, ResultDownloadSaleStockResultListType } from "@/api/dto";
import {
  BargainType,
  IntegrationBargainName,
  OrderInstructionStatusDetail,
  ProductCsv,
  ProductTrendTableHeader,
  SkuCsv,
  StoreActualsTableHeader,
  ActionStatusDetail,
  ActionTypeDetail,
  ProductStatusInfo,
  ItemNumberLogicCodeItem,
  RadialConnectionItem,
  SmaregiCsvItems,
  ShopifyCsvItems,
  ProductStatusDetail,
  CustomValueTypeItem
} from "./model";

export class Constant {
  static readonly EC_FUTURESHOP = "EC-futureshop";
  static readonly apiLimit = 1000;
  static readonly janCodePrefixList = [20, 21, 22, 23, 24, 25, 26, 27, 28, 29];
  static readonly genderOptions: string[] = ["男性", "女性", "ユニセックス"];
  static readonly genderList = [
    {
      id: 0,
      text: "男性",
    },
    {
      id: 1,
      text: "女性",
    },
    {
      id: 2,
      text: "ユニセックス",
    },
  ];
  static readonly storeOptions: ProductStore[] = ["ALL", "STORE", "EC"];
  static readonly seasonColumns: string[] = ["過去", "今期", "来期以降", "定番"];
  static readonly itemNumberLogicCodeItems: ItemNumberLogicCodeItem[] = [
    {
      value: "${season}",
      text: "シーズンコード",
    },
    {
      value: "${brand}",
      text: "ブランドコード",
    },
    {
      value: "${category}",
      text: "カテゴリコード",
    },
    {
      value: "${attribute1}",
      text: "属性1コード",
    },
    {
      value: "${attribute2}",
      text: "属性2コード",
    },
    {
      value: "${attribute3}",
      text: "属性3コード",
    },
  ];
  static readonly storeActualsSaleTableHeader: StoreActualsTableHeader[] = [
    {
      text: "店舗",
      value: "store",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-7 pr-0",
      sortable: false,
    },
    {
      text: "売上実績",
      value: "saleResult",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 px-0",
      sortable: true,
    },
    {
      text: "予算比",
      value: "salePerBudget",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-2 pl-0",
      sortable: false,
    },
    {
      text: "シェア",
      value: "share",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0 pl-1",
      sortable: false,
    },
  ];
  static readonly storeActualsOtherTableHeader: StoreActualsTableHeader[] = [
    {
      text: "店舗",
      value: "store",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-7 pr-0",
      sortable: false,
    },
    {
      text: "客単価",
      value: "unitPrice",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 px-0",
      sortable: true,
    },
    {
      text: "購入客数",
      value: "purchasersCount",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0 pl-0",
      sortable: true,
    },
    {
      text: "セット率",
      value: "setRate",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-0 pr-0 store-actuals-header-setrate",
      sortable: true,
    },
  ];
  static readonly productTrendTableHeader: ProductTrendTableHeader[] = [
    {
      text: "店舗 / 倉庫",
      value: "store",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-10",
      sortable: false,
    },
    {
      text: "年間売上",
      value: "unitPrice",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-2",
      sortable: true,
    },
    {
      text: "0週",
      value: "0week",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0",
      sortable: true,
    },
    {
      text: "-1週",
      value: "-1week",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0",
      sortable: true,
    },
    {
      text: "-2週",
      value: "-2week",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0",
      sortable: true,
    },
    {
      text: "-3週",
      value: "-3week",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0",
      sortable: true,
    },
    {
      text: "-4週",
      value: "-4week",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pr-0",
      sortable: true,
    },
    {
      text: "在庫数",
      value: "stockCount",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-0 pr-0",
      sortable: true,
    },
    {
      text: "消化率",
      value: "digestionRate",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-0 pr-0",
      sortable: true,
    },
    {
      text: "消化週数",
      value: "storageLife",
      align: "left",
      class: "body-2-noto-sans radial-assistant-type-70 pl-0 pr-0",
      sortable: true,
    },
  ];
  static readonly bargainTypes: BargainType[] = [
    {
      id: 1,
      name: "セール区分扱い",
      number: 0,
    },
    {
      id: 2,
      name: "販促費扱い",
      number: 1,
    },
  ];
  static readonly integrationBargainNames: IntegrationBargainName[] = [
    {
      name: "custom",
      productBargains: [
        {
          key: "productBargain",
          value: "商品セール値引き",
        },
      ],
      headBargains: [
        {
          key: "headPointBargain",
          value: "ポイント値引き",
        },
        {
          key: "headCouponBargain",
          value: "クーポン値引き",
        },
        {
          key: "headBundleBargain",
          value: "バンドル値引き",
        },
        {
          key: "headRoundingBargain",
          value: "端数値値引き",
        },
      ],
    },
    {
      name: "smaregi",
      productBargains: [
        {
          key: "productUnitDiscountPrice",
          value: "店頭単品値引き",
        },
        {
          key: "productApplyBargainDiscountPrice",
          value: "適用セール値引き",
        },
      ],
      headBargains: [
        {
          key: "headDiscountPriceProportional",
          value: "小計値値引き",
        },
        {
          key: "headDiscountCouponProportional",
          value: "クーポン値引き",
        },
        {
          key: "headProductBundleProportional",
          value: "バンドル値引き",
        },
        {
          key: "headRoundingPriceProportional",
          value: "端数値値引き",
        },
      ],
    },
    {
      name: "futureshop",
      productBargains: [],
      headBargains: [
        {
          key: "headVolumeDiscountTotal",
          value: "割引合計金額",
        },
        {
          key: "headProductCouponDiscountTotal",
          value: "クーポン値引き",
        },
      ],
    },
    {
      name: "shopifyPOS",
      productBargains: [
        {
          key: "posProductUnitDiscountPrice",
          value: "店頭単品値引き",
        },
      ],
      headBargains: [
        {
          key: "posHeadDiscountPrice",
          value: "小計値引き",
        },
      ],
    },
    {
      name: "shopifyEC",
      productBargains: [
        {
          key: "ecProductUnitDiscountPrice",
          value: "店頭単品値引き",
        },
      ],
      headBargains: [
        {
          key: "ecHeadDiscountPrice",
          value: "小計値引き",
        },
      ],
    },
    {
      name: "base",
      productBargains: [],
      headBargains: [],
    },
  ];

  static readonly radialConnections: RadialConnectionItem[] = [
    { key: "JANCODE", name: "JANコード" },
    { key: "ITEMNUMBERCOLORCODESIZECODE", name: "品番+カラーコード+サイズコード" },
    { key: "ITEMNUMBERSIZECODECOLORCODE", name: "品番+サイズコード+カラーコード" },
    { key: "BRANDNAME", name: "ブランド名" },
    { key: "BRANDCODE", name: "ブランドコード" },
    { key: "CATEGORYNAME", name: "アイテムカテゴリ名" },
    { key: "CATEGORYCODE", name: "アイテムカテゴリコード" },
    { key: "ITEMNAME", name: "商品名" },
    { key: "ITEMNUMBER", name: "品番" },
    { key: "SALESPRICE", name: "販売価格" },
    { key: "PRICE", name: "定価" },
    { key: "COST", name: "原価" },
    { key: "SALESSTARTEDAT", name: "販売開始日" },
    { key: "SALESPRICEUPDATEDAT", name: "販売価格更新日" },
    { key: "SALESENDEDAT", name: "販売終了日" },
    { key: "DESCRIPTION", name: "商品説明" },
    { key: "COLORCODE", name: "カラーコード" },
    { key: "COLORNAME", name: "カラー名" },
    { key: "SIZECODE", name: "サイズコード" },
    { key: "SIZENAME", name: "サイズ名" },
    { key: "COMPOSITION", name: "組成表示" },
    { key: "FREECOMMENT", name: "フリーコメント" },
    { key: "DIRECTIONMEMO", name: "ディレクションメモ" },
    { key: "FACTORYNAME", name: "仕入れ先名" },
    { key: "FACTORYCODE", name: "仕入れ先コード" },
    { key: "ATTRIBUTE1NAME", name: "属性1名" },
    { key: "ATTRIBUTE1CODE", name: "属性1コード" },
    { key: "ATTRIBUTE2NAME", name: "属性2名" },
    { key: "ATTRIBUTE2CODE", name: "属性2コード" },
    { key: "ATTRIBUTE3NAME", name: "属性3名" },
    { key: "ATTRIBUTE3CODE", name: "属性3コード" },
    { key: "SEASONNAME", name: "シーズン名" },
    { key: "SEASONCODE", name: "シーズンコード" },
  ];
  static readonly smaregiCsvItems: SmaregiCsvItems = {
    requiredItems: [
      {
        name: "商品コード",
        key: "itemCode",
        setting: [{ key: "JANCODE", name: "JANコード" }],
      },
      {
        name: "部門ID",
        key: "category",
        setting: [
          { key: "BRANDCODE", name: "ブランドコード(推奨)" },
          { key: "CATEGORYCODE", name: "カテゴリーコード(推奨)" },
          { key: "ITEMNUMBER", name: "品番" },
          { key: "JANCODE", name: "JANコード" },
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
          { key: "ATTRIBUTE1CODE", name: "属性1コード" },
          { key: "ATTRIBUTE2CODE", name: "属性2コード" },
          { key: "ATTRIBUTE3CODE", name: "属性3コード" },

        ],
      },
      {
        name: "商品名",
        key: "productName",
        setting: [{ key: "PRODUCTNAME", name: "商品名" }],
      },
      {
        name: "商品ID",
        key: "productNumber",
        setting: [
          { key: "JANCODE", name: "JANコード" },
          { key: "ITEMNUMBERCOLORCODESIZECODE", name: "品番+カラーコード+サイズコード" },
          { key: "ITEMNUMBERSIZECODECOLORCODE", name: "品番+サイズコード+カラーコード" },
          { key: "EMPTY", name: "空欄" },
        ],
      },
      {
        name: "商品単価",
        key: "unitPrice",
        setting: [
          { key: "SALESPRICE", name: "販売価格" },
          { key: "PRICE", name: "定価" },
        ],
      },
    ],
    optionalItems: [
      {
        name: "品番",
        key: "ITEMNUMBER",
        setting: [{ key: "ITEMNUMBER", name: "品番" }],
      },
      {
        name: "適用開始日時",
        key: "APPSTARTDATETIME",
        setting: [
          { key: "SALESSTARTEDAT", name: "販売開始日" },
          { key: "SALESPRICEUPDATEDAT", name: "販売価格更新日" },
        ],
      },
      {
        name: "説明",
        key: "DESCRIPTION",
        setting: [{ key: "DESCRIPTION", name: "商品説明" }],
      },
      {
        name: "カラー",
        key: "COLOR",
        setting: [
          { key: "COLORCODE", name: "カラーコード" },
          { key: "COLORNAME", name: "カラー名" },
        ],
      },
      {
        name: "原価",
        key: "COST",
        setting: [{ key: "COST", name: "原価" }],
      },
      {
        name: "サイズ",
        key: "SIZE",
        setting: [
          { key: "SIZECODE", name: "サイズコード" },
          { key: "SIZENAME", name: "サイズ名" },
        ],
      },
      {
        name: "グループコード",
        key: "GROUPCODE",
        setting: [
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
          { key: "ATTRIBUTE1CODE", name: "属性1コード" },
          { key: "ATTRIBUTE2CODE", name: "属性2コード" },
          { key: "ATTRIBUTE3CODE", name: "属性3コード" },
        ],
      },
      {
        name: "仕入原価",
        key: "PURCHASECOST",
        setting: [
          { key: "COST", name: "原価" },
          { key: "FREECOMMENT", name: "フリーコメント" },
        ],
      },
    ],
    originalItems: [
      {
        name: "税区分",
        key: "TAXDIVISION",
        setting: [
          { key: "0", name: "0: 税込" },
          { key: "1", name: "1: 税抜き" },
          { key: "2", name: "2: 非課税" },
        ],
      },
    ],
  };

  static readonly shopifyCsvItems: ShopifyCsvItems = {
    requiredItems: [
      {
        name: "Title",
        key: "TITLE",
        setting: [
          { key: "ITEMNAME", name: "商品名" },
        ],
      },
    ],
    optionalItems: [
      {
        name: "Handle",
        key: "HANDLE",
        multiple: false,
        setting: [
          { key: "ITEMNUMBER", name: "品番" },
        ],
      },
      {
        name: "Body HTML",
        key: "BODYHTML",
        multiple: false,
        setting: [
          { key: "DESCRIPTION", name: "商品説明" },
        ],
      },
      {
        name: "Vendor",
        key: "VENDOR",
        multiple: false,
        setting: [
          { key: "BRANDNAME", name: "ブランド名" },
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
        ],
      },
      {
        name: "Tags",
        key: "TAGS",
        multiple: false,
        setting: [
          { key: "FREECOMMENT", name: "フリーコメント" },
        ],
      },
      {
        name: "Option1 Value",
        key: "OPTION1VALUE",
        multiple: false,
        setting: [
          { key: "COLORNAME", name: "カラー名" },
          { key: "SIZENAME", name: "サイズ名" },
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
        ],
      },
      {
        name: "Option2 Value",
        key: "OPTION2VALUE",
        multiple: false,
        setting: [
          { key: "COLORNAME", name: "カラー名" },
          { key: "SIZENAME", name: "サイズ名" },
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
        ],
      },
      {
        name: "Option3 Value",
        key: "OPTION3VALUE",
        multiple: false,
        setting: [
          { key: "COLORNAME", name: "カラー名" },
          { key: "SIZENAME", name: "サイズ名" },
          { key: "ATTRIBUTE1NAME", name: "属性1名" },
          { key: "ATTRIBUTE2NAME", name: "属性2名" },
          { key: "ATTRIBUTE3NAME", name: "属性3名" },
        ],
      },
      {
        name: "Variant SKU",
        key: "SKU",
        multiple: false,
        setting: [
          { key: "JANCODE", name: "JANコード" },
          { key: "ITEMNUMBERCOLORCODESIZECODE", name: "品番+カラーコード+サイズコード" },
          { key: "ITEMNUMBERSIZECODECOLORCODE", name: "品番+サイズコード+カラーコード" },
        ],
      },
      {
        name: "Variant Barcode",
        key: "BARCODE",
        multiple: false,
        setting: [
          { key: "JANCODE", name: "JANコード" },
          { key: "ITEMNUMBERCOLORCODESIZECODE", name: "品番+カラーコード+サイズコード" },
          { key: "ITEMNUMBERSIZECODECOLORCODE", name: "品番+サイズコード+カラーコード" },
        ],
      },
      {
        name: "Variant Price",
        key: "PRICE",
        multiple: false,
        setting: [
          { key: "SALESPRICE", name: "販売価格" },
        ],
      },
      {
        name: "Variant Compare at Price",
        key: "COMPAREATPRICE",
        multiple: false,
        setting: [
          { key: "PRICE", name: "定価" },
        ],
      },
      {
        name: "Type",
        key: "PRODUCTTYPE",
        multiple: true,
        setting: this.radialConnections,
      },
    ],
    originalItems: [
      {
        name: "Option1 Name",
        key: "OPTION1NAME",
        setting: [
          { key: "カラー", name: "カラー" },
          { key: "サイズ", name: "サイズ" },
          { key: "属性1", name: "属性1" },
          { key: "属性2", name: "属性2" },
          { key: "属性3", name: "属性3" },
          { key: "color", name: "color" },
          { key: "size", name: "size" },
        ],
      },
      {
        name: "Option2 Name",
        key: "OPTION2NAME",
        setting: [
          { key: "カラー", name: "カラー" },
          { key: "サイズ", name: "サイズ" },
          { key: "属性1", name: "属性1" },
          { key: "属性2", name: "属性2" },
          { key: "属性3", name: "属性3" },
          { key: "color", name: "color" },
          { key: "size", name: "size" },
        ],
      },
      {
        name: "Option3 Name",
        key: "OPTION3NAME",
        setting: [
          { key: "カラー", name: "カラー" },
          { key: "サイズ", name: "サイズ" },
          { key: "属性1", name: "属性1" },
          { key: "属性2", name: "属性2" },
          { key: "属性3", name: "属性3" },
          { key: "color", name: "color" },
          { key: "size", name: "size" },
        ],
      },
      {
        name: "Variant Inventory Tracker",
        key: "INVENTORYMANAGEMENT",
        setting: [
          { key: "shopify", name: "shopify" },
          { key: "shipwire", name: "shipwire" },
          { key: "amazon_marketplace_web", name: "amazon_marketplace_web" },
        ],
      },
      {
        name: "Variant Taxable",
        key: "TAXABLE",
        setting: [
          { key: "1", name: "TRUE" },
          { key: "0", name: "FALSE" },
        ],
      },
      {
        name: "Variant Requires Shipping",
        key: "REQUIRESSHIPPING",
        setting: [
          { key: "1", name: "TRUE" },
          { key: "0", name: "FALSE" },
        ],
      }
    ],
  }

  static readonly alertDatas = [
    {
      number: 0,
      name: "在庫フォロー",
      color: "radialSecondaryGreen70",
      storeIntegration: true,
      description:
        "店舗在庫が少なくなり、倉庫在庫に余裕があるSKUにアラートがあがります。価値ある在庫を正確に把握し、店頭で販売機会を作りましょう。",
      comment: "このアラートは、SKUのデータを分析し、SKUに対してあがります。",
    },
    {
      number: 1,
      name: "店間移動",
      color: "radialSecondaryGreen70",
      storeIntegration: true,
      description:
        "店舗ごとの売行きに違いがあるSKUにアラートがあがります。「売れ方の個性」に合わせて、在庫を最適に配置しましょう。",
      comment: "このアラートは、SKUのデータを分析し、SKUに対してあがります。",
    },
    {
      number: 2,
      name: "追加発注",
      color: "radialInfo70",
      storeIntegration: false,
      description:
        "販売期間に比べ、早めに在庫がなくなりそうなSKUにタグが付与されます。追加発注を検討し、商品のポテンシャルを活かしましょう。",
      comment:
        "このタグは、SKUのデータを分析し、SKUに対して付与されます。販売期間が未入力、販売期間外及びセール商品には付与されません。",
    },
    {
      number: 3,
      name: "売り切れ",
      color: "radialSecondaryGray70",
      storeIntegration: false,
      description:
        "店舗・倉庫ともにすべての在庫がなくなったSKUにアラートがあがります。このアラートがあがったSKUは売り切ることができました。",
      comment: "このアラートは、SKUのデータを分析し、SKUに対してあがります。",
    },
    {
      number: 4,
      name: "UP",
      color: "radialSecondaryGray70",
      storeIntegration: false,
      description: "売行きが急激に上昇した品番にアラートがあがります。追加発注など次のアクションを検討しましょう。",
      comment: "このアラートは、SKUのデータを分析し、品番に対してあがります。",
    },
    {
      number: 5,
      name: "DOWN",
      color: "radialSecondaryGray70",
      storeIntegration: false,
      description:
        "売行きが急激に低下した品番にアラートがあがります。店頭陳列の再配置や在庫調整など次のアクションを検討しましょう。",
      comment: "このアラートは、SKUのデータを分析し、品番に対してあがります。",
    },
    {
      number: 6,
      name: "倉庫返却",
      color: "radialSecondaryGreen70",
      storeIntegration: false,
      description:
        "店舗で在庫が余っており、倉庫に在庫がないSKUにアラートがあがります。倉庫に在庫移動することで、ECや卸向けに販売する機会を作りましょう。",
      comment: "このアラートは、SKUのデータを分析し、SKUに対してあがります。",
    },
    {
      number: 7,
      name: "売残りリスク",
      color: "radialDanger70",
      storeIntegration: false,
      description:
        "販売開始日から日数が経過しても、販売動向が悪く、在庫も大量に余っているSKUにタグが付与されます。リスクを軽減する施策を検討しましょう。",
      comment:
        "このタグは、SKUのデータを分析し、SKUに対して付与されます。販売期間が未入力、販売期間外及びセール商品には付与されません。",
    },
  ];
  static get version(): string | null {
    return require("@/../package.json").version ?? null;
  }
  static readonly MdMapView = [
    {
      id: "delivery",
      name: "新規投入品番",
    },
    {
      id: "additional",
      name: "追加発注品番",
    },
  ];
  static readonly BrandCategorySeasonPlanView = [
    {
      id: "total",
      name: "売上仕入計画",
    },
    {
      id: "buying",
      name: "仕入構成比一覧",
    },
    {
      id: "sale",
      name: "売上構成比一覧",
    },
  ];
  static readonly MDStatus: ProductStatusDetail[] = [
    {
      id: "PLANNING",
      name: "企画中",
      background: "radialInfo10",
      letter: "radialAssistantType70",
    },
    {
      id: "FIRSTSAMPLE",
      name: "1st Sample",
      background: "radialInfo30",
      letter: "radialAssistantType0",
    },
    {
      id: "SECONDSAMPLE",
      name: "2nd Sample",
      background: "radialInfo50",
      letter: "radialAssistantType0",
    },
    {
      id: "ORDERED",
      name: "発注済",
      background: "radialInfo70",
      letter: "radialAssistantType0",
    },
    {
      id: "DROP",
      name: "企画中止",
      background: "radialDanger40",
      letter: "radialAssistantType0",
    },
  ];

  static readonly ProductStatus: ProductStatusInfo[] = [
    {
      id: "PLANNING",
      name: "企画中",
    },
    {
      id: "FIRSTSAMPLE",
      name: "1st Sample",
    },
    {
      id: "SECONDSAMPLE",
      name: "2nd Sample",
    },
    {
      id: "ORDERED",
      name: "発注済",
    },
    {
      id: "DROP",
      name: "企画中止",
    },
  ];

  static readonly OrderProductStatus: { id: OrderProductStockedStatus; name: string }[] = [
    // 今後チップUI変更するタイミングで, background, letterを追加する
    {
      id: "PREORDER",
      name: "未定",
    },
    {
      id: "ORDERED",
      name: "発注済み",
    },
    {
      id: "STOCKED",
      name: "納品済み",
    },
    {
      id: "DROP",
      name: "発注中止",
    },
  ];

  static readonly OrderInstructionStatus: OrderInstructionStatusDetail[] = [
    {
      id: "PREORDER",
      name: "未定",
    },
    {
      id: "ORDERED",
      name: "発注済み",
    },
    {
      id: "PARTLYSTOCKED",
      name: "一部納品済み",
    },
    {
      id: "STOCKED",
      name: "納品済み",
    },
    {
      id: "DROP",
      name: "発注中止",
    },
  ];

  static readonly pieChartProductColors = [
    {
      id: "PLANNING",
      name: "info10",
      color: "#E5F2F9",
    },
    {
      id: "FIRSTSAMPLE",
      name: "info30",
      color: "#76B3E0",
    },
    {
      id: "SECONDSAMPLE",
      name: "info50",
      color: "#5EA3DA",
    },
    {
      id: "ORDERED",
      name: "info70",
      color: "#4487C8",
    },
    {
      id: "DROP",
      name: "danger40",
      color: "#EF8195",
    },
  ];

  static readonly pieChartMdMapSummaryColors = [
    {
      id: 0,
      name: "pg90",
      color: "#4B7580",
    },
    {
      id: 1,
      name: "pg80",
      color: "#5B8D99",
    },
    {
      id: 2,
      name: "pg70",
      color: "#7DA8B2",
    },
    {
      id: 3,
      name: "pg60",
      color: "#9FC4CC",
    },
    {
      id: 4,
      name: "pg50",
      color: "#CAE0E5",
    },
    {
      id: 5,
      name: "pg40",
      color: "#DFEFF2",
    },
    {
      id: 6,
      name: "pg30",
      color: "#E6F0F2",
    },
    {
      id: 7,
      name: "pg20",
      color: "#F0F6F7",
    },
  ];

  static readonly productCsvHeaders: string[] = [
    "商品番号",
    "ステータス",
    "商品名",
    "ブランド",
    "シーズン",
    "アイテムカテゴリ",
    "性別",
    "仕入れ先",
    "販売店",
    "属性1",
    "属性2",
    "属性3",
    "販売開始予定日",
    "販売終了予定日",
    "定価",
    "販売価格",
    "販売価格更新日",
    "組成表示",
    "商品説明",
    "フリーコメント",
    "ディレクションメモ",
    "生産リードタイム",
    "最小発注数"
  ];
  static readonly skuCsvHeaders: string[] = ["商品番号", "JANコード", "カラーコード", "サイズコード", "原価"];
  static readonly skuSmaregiCsvHeaders: string[] = ["商品コード", "部門ID", "商品名", "商品ID", "商品単価"];
  static readonly skuShopifyCsvHeaders: string[] = ["Title"];
  static readonly productCsvSample: ProductCsv = {
    itemNumber: "1",
    status: 0,
    name: "LOGO EMBROIDELY T-SHIRT",
    brand: "AMBUSH",
    season: "20SS",
    category: "Tシャツ",
    gender: 2,
    factory: "REALM",
    store: 0,
    attribute1: "刺しゅう",
    attribute2: "半袖",
    attribute3: "",
    salesStartedAt: "2021/02/23",
    salesEndedAt: "2021/02/24",
    price: 10000,
    salesPrice: 8000,
    salesPriceUpdatedAt: "",
    composition: "本体:コットン100%",
    description: "ハイネックデザインが洗練された表情をかもし出す、シンプルながらもディテールにこだわったTシャツ。",
    freeComment: "",
    directionMemo: "",
  };

  static readonly skuCsvSample: SkuCsv = {
    itemNumber: "1",
    janCode: "ABCD12345",
    colorCode: "12ab",
    sizeCode: "34cd",
    cost: 6000,
  };
  static readonly ga4TrackingIdDatas: { companyId: string; trackingId: string }[] = [
    { companyId: "3c4eb2e6-1b21-419d-a844-f6e083b62787", trackingId: "G-P6MPEBY41D" }, // ver4.3テスト寺村(develop)
    { companyId: "8dd00b88-5311-44e8-ad3d-4564965fb76b", trackingId: "G-CPV8N4T1DW" }, // devテスター用(木下）(develop)
    { companyId: "e7bdac14-e3c4-44e6-8015-2d2f94d4cb19", trackingId: "G-G1CP61V49M" }, // 株式会社シライ(master)
    { companyId: "06077ae0-974c-4578-84a3-d277529ea74f", trackingId: "G-913YFRSWRQ" }, // 株式会社マインドウィンド(master)
    { companyId: "39ff4687-c577-434b-b16c-000e05a610f6", trackingId: "G-CLNT2PLT3E" }, // 株式会社アン・ドゥー(master)
    { companyId: "6ddae14f-422a-46dd-ad75-6c2785c15667", trackingId: "G-PYYM608ECP" }, // lucifer research株式会社(master)
    { companyId: "f652c77e-3673-42a7-8c6f-c8a76b7aed35", trackingId: "G-LZGN8XWV94" }, // stg-デモアカウント(staging)
    // { companyId: "", trackingId: "" } アカウントが追加されたらcompanyIdとtrackingIdのペアを追加する
  ];
  static readonly writableAdminUserNames: (string | undefined)[] = ["root", "admin"];
  static isUsingTablet(): boolean {
    const width = window.parent.screen.width;
    if (width > 1200) {
      return false;
    } else {
      return true;
    }
  }
  static readonly actionStatues: ActionStatusDetail[] = [
    { key: "PENDING", name: "未対応" },
    { key: "COMPLETED", name: "対応済み" },
  ];
  static readonly actionTypes: ActionTypeDetail[] = [
    { key: "SALE", name: "セール" },
    { key: "CUSTOM", name: "カスタム" },
  ];
  static readonly productCustomActionCsvHeaders: string[] = [
    "品番",
    "商品名",
    "JANコード",
    "カラー",
    "サイズ",
    "シーズン",
    "ブランド",
    "アイテムカテゴリー",
    "販売価格",
    "販売開始日",
    "販売終了日",
  ];
  static readonly productSaleActionCsvHeaders: string[] = [
    "品番",
    "商品名",
    "シーズン",
    "ブランド",
    "販売価格",
    "販売開始日",
    "販売終了日",
    "旧価格",
    "新価格",
  ];
  static readonly skuStockActionCsvHeaders: string[] = [
    "品番",
    "商品名",
    "カラー",
    "サイズ",
    "移動元",
    "移動先",
    "移動在庫数",
  ];
  static readonly skuCustomActionCsvHeaders: string[] = [
    "品番",
    "商品名",
    "JANコード",
    "カラー",
    "サイズ",
    "シーズン",
    "ブランド",
    "アイテムカテゴリ",
  ];
  static readonly skuInterstoreTransferActionCsvHeaders: string[] = [
    "品番",
    "商品名",
    "JANコード",
    "カラー",
    "サイズ",
    "移動元店舗名",
    "移動元店舗在庫数",
    "移動先店舗名",
    "移動先店舗在庫数",
    "移動在庫数",
  ];
  static readonly customValueTypes: CustomValueTypeItem[] = [{ name: "文字列", value: "STRING" }, { name: "数値", value: "NUMBER" }];
  static readonly RadialConnectionType: RadialConnection[] = [
    'JANCODE',
    'ITEMNUMBERCOLORCODESIZECODE',
    'ITEMNUMBERSIZECODECOLORCODE',
    'BRANDNAME',
    'BRANDCODE',
    'CATEGORYNAME',
    'CATEGORYCODE',
    'ITEMNAME',
    'ITEMNUMBER',
    'SALESPRICE',
    'PRICE',
    'COST',
    'SALESSTARTEDAT',
    'SALESPRICEUPDATEDAT',
    'SALESENDEDAT',
    'DESCRIPTION',
    'COLORCODE',
    'COLORNAME',
    'SIZECODE',
    'SIZENAME',
    'COMPOSITION',
    'FREECOMMENT',
    'DIRECTIONMEMO',
    'FACTORYNAME',
    'FACTORYCODE',
    'ATTRIBUTE1NAME',
    'ATTRIBUTE1CODE',
    'ATTRIBUTE2NAME',
    'ATTRIBUTE2CODE',
    'ATTRIBUTE3NAME',
    'ATTRIBUTE3CODE',
    'SEASONNAME',
    'SEASONCODE'
  ];
  static readonly SmaregiCategoryType: RadialConnection[] = [
    'BRANDCODE',
    'CATEGORYCODE',
    'ITEMNUMBER',
    'JANCODE',
    'ATTRIBUTE1NAME',
    'ATTRIBUTE1CODE',
    'ATTRIBUTE2NAME',
    'ATTRIBUTE2CODE',
    'ATTRIBUTE3NAME',
    'ATTRIBUTE3CODE',
    'CUSTOMPRODUCT',
    'CUSTOMSKU'
  ];
  static readonly SmaregiUnitPriceType: RadialConnection[] = ['PRICE', 'SALESPRICE', 'CUSTOMPRODUCT', 'CUSTOMSKU'];
  static readonly SmaregiProductNumberType: RadialConnection[] = ['JANCODE',
    'ITEMNUMBERCOLORCODESIZECODE',
    'ITEMNUMBERSIZECODECOLORCODE',
    'EMPTY',
    'CUSTOMPRODUCT',
    'CUSTOMSKU'];
  static readonly ProductMasterListTypes: { name: string; value: ResultDownloadProductMasterListType }[][] = [
    [{ name: "商品名", value: "ITEM_NAME" }, { name: "品番(必須)", value: "ITEM_NUMBER" }],
    [{ name: "シーズン名", value: "SEASON_NAME" }, { name: "シーズンコード", value: "SEASON_CODE" }, { name: "ブランド名", value: "BRAND_NAME" }, { name: "ブランドコード", value: "BRAND_CODE" }, { name: "アイテムカテゴリ名", value: "CATEGORY_NAME" }, { name: "アイテムカテゴリコード", value: "CATEGORY_CODE" }],
    [{ name: "属性1名", value: "ATTRIBUTE1_NAME" }, { name: "属性1コード", value: "ATTRIBUTE1_CODE" }, { name: "属性2名", value: "ATTRIBUTE2_NAME" }, { name: "属性2コード", value: "ATTRIBUTE2_CODE" }, { name: "属性3名", value: "ATTRIBUTE3_NAME" }, { name: "属性3コード", value: "ATTRIBUTE3_CODE" }],
    [{ name: "定価", value: "PRICE" }, { name: "販売価格", value: "SALES_PRICE" }],
    [{ name: "仕入先名", value: "FACTORY_NAME" }, { name: "仕入先コード", value: "FACTORY_CODE" }],
    [{ name: "フリーコメント", value: "FREE_COMMENT" }, { name: "カスタムタグ", value: "CUSTOM_TAG" }, { name: "アラート", value: "ALERT" }],
  ]
  static readonly SkuMasterListTypes: { name: string; value: ResultDownloadSkuMasterListType }[][] = [
    [{ name: "商品名", value: "ITEM_NAME" }, { name: "品番", value: "ITEM_NUMBER" }, { name: "JANコード(必須)", value: "JAN_CODE" }],
    [{ name: "カラー名", value: "COLOR_NAME" }, { name: "カラーコード", value: "COLOR_CODE" }, { name: "サイズ名", value: "SIZE_NAME" }, { name: "サイズコード", value: "SIZE_CODE" }],
    [{ name: "シーズン名", value: "SEASON_NAME" }, { name: "シーズンコード", value: "SEASON_CODE" }, { name: "ブランド名", value: "BRAND_NAME" }, { name: "ブランドコード", value: "BRAND_CODE" }, { name: "アイテムカテゴリ名", value: "CATEGORY_NAME" }, { name: "アイテムカテゴリコード", value: "CATEGORY_CODE" }],
    [{ name: "属性1名", value: "ATTRIBUTE1_NAME" }, { name: "属性1コード", value: "ATTRIBUTE1_CODE" }, { name: "属性2名", value: "ATTRIBUTE2_NAME" }, { name: "属性2コード", value: "ATTRIBUTE2_CODE" }, { name: "属性3名", value: "ATTRIBUTE3_NAME" }, { name: "属性3コード", value: "ATTRIBUTE3_CODE" }],
    [{ name: "定価", value: "PRICE" }, { name: "販売価格", value: "SALES_PRICE" }, { name: "原価", value: "COST" }],
    [{ name: "仕入先名", value: "FACTORY_NAME" }, { name: "仕入先コード", value: "FACTORY_CODE" }],
    [{ name: "フリーコメント", value: "FREE_COMMENT" }, { name: "カスタムタグ", value: "CUSTOM_TAG" }, { name: "アラート", value: "ALERT" }],
  ]
  static readonly SaleStockResultListTypes: { name: string; value: ResultDownloadSaleStockResultListType }[][] = [
    [{ name: "売上個数（全体）", value: "TOTAL_SALE_QUANTITY" }, { name: "売上個数（プロパー）", value: "SALE_QUANTITY_FOR_PROPER" }, { name: "売上個数（セール）", value: "SALE_QUANTITY_ON_SALE" }
      , { name: "売上金額（全体）", value: "TOTAL_SALES_AMOUNT" }, { name: "売上金額（プロパー）", value: "SALE_AMOUNT_FOR_PROPER" }, { name: "売上金額（セール）", value: "SALE_AMOUNT_ON_SALE" }],
    [{ name: "値引金額合計", value: "TOTAL_DISCOUNT_PRICE" }, { name: "値引率", value: "DISCOUNT_RATE" }, { name: "在庫個数", value: "STOCK_COUNT" }
      , { name: "在庫金額", value: "STOCK_AMOUNT" }, { name: "消化週数", value: "STORAGE_LIFE" }, { name: "消化率", value: "DIGESTION_RATE" }],
  ]
  static readonly productStatusInput: { id: number; name: string; code: ProductStatus }[] = [
    { id: 0, name: "企画中", code: "PLANNING" },
    { id: 1, name: "1st sample", code: "FIRSTSAMPLE" },
    { id: 2, name: "2nd sample", code: "SECONDSAMPLE" },
    { id: 3, name: "発注済み", code: "ORDERED" },
    { id: 4, name: "企画中止", code: "DROP" },
  ]
  static readonly shopifyJanCodeRecognitionTypes: { name: String, value: ShopifyJanCodeRecognitionKey }[] = [
    {name: "SKU", value: 'SKU'},
    {name: "バーコード", value: 'BARCODE'}
  ]
}

export const allStatus = ["PLANNING", "FIRSTSAMPLE", "SECONDSAMPLE", "DROP", "ORDERED"] as const;
export type ProductStatus = (typeof allStatus)[number];
export const getStatusIndex = (status: string) => {
  const statusIndex = allStatus.findIndex((e) => e === status);
  if (statusIndex < 0) {
    return 0;
  } else {
    return statusIndex;
  }
};
export const getStatus = (statusIndex: number) => {
  return allStatus[statusIndex] ?? allStatus[0];
};

export const allStore = ["ALL", "STORE", "EC"] as const;
export type ProductStore = (typeof allStore)[number];
export const getStoreIndex = (store: string | null) => {
  const storeIndex = allStore.findIndex((e) => e === store);
  if (storeIndex < 0) {
    return null;
  } else {
    return storeIndex;
  }
};
export const getStore = (storeIndex: number) => {
  return allStore[storeIndex] ?? allStore[0];
};

export type DigestRateBase = "quantity" | "price" | "cost";
