
import Vue from "vue";
import { Store, InterstoreTransferGroup } from "@/api/entities";
import { CreateInterstoreTransferGroupDto, UpdateInterstoreTransferGroupDto, UpdateStoreDto } from "@/api/dto";
import draggable from "vuedraggable";
import mixin from "@/mixin/mixin";
import Pagination from "@/components/vuetify/Pagination.vue";
import ConfirmationDialog from "@/components/vuetify/ConfirmationDialog.vue";
import RadialApiClient from "@/api/RadialApiClient";
import store from "@/store";

export class StoreGroup {
  group: InterstoreTransferGroup;
  stores: (Store & { index: number })[];
}

export interface DataType {
  storeGroups: StoreGroup[];
  activeItem: string | null;
  buttonDisabled: boolean;
  selectedGroup: StoreGroup | null;
  editingGroupName: string | null;
  selectedStoreForGroup: Store | null;
  storeIdToDeleteFromGroup: string | null;
  newGroupName: string | null;
  dialogCreateGroup: boolean;
  dialogEditGroup: boolean;
  dialogDeleteGroup: boolean;
  dialogDeleteStore: boolean;
}

export default Vue.extend({
  name: "StoreGroupSetting",
  mixins: [mixin],
  components: {
    draggable,
    Pagination,
    ConfirmationDialog,
  },
  props: {
    stores: {
      type: Array as () => Store[],
      required: true,
    },
  },
  data(): DataType {
    return {
      storeGroups: [],
      activeItem: null,
      buttonDisabled: false,
      selectedGroup: null,
      editingGroupName: null,
      selectedStoreForGroup: null,
      storeIdToDeleteFromGroup: null,
      newGroupName: null,
      dialogCreateGroup: false,
      dialogEditGroup: false,
      dialogDeleteGroup: false,
      dialogDeleteStore: false,
    };
  },
  methods: {
    async updateStoreGroupIndex(event: any, storeGroup: StoreGroup) {
      this.$emit("isLoading", true);
      const newIndex = event.newIndex;
      const oldIndex = event.oldIndex;
      if (oldIndex === newIndex) {
        // 処理なし
      } else if (oldIndex < newIndex) {
        // 上から下に動かした場合の処理
        const minIndex = storeGroup.stores[newIndex]?.index;
        const maxIndex = storeGroup.stores[newIndex - 1]?.index;
        // 更新にあたって必要十分なブランドカテゴリの取得
        const updatedStores = storeGroup.stores.filter((category) => {
          return category.index >= minIndex && category.index <= maxIndex;
        });
        updatedStores.forEach((updatedCategory, index) => {
          // const id = updatedCategory.storeGroupId;
          // if (updatedStores.length === index + 1) {
          //   // ドラッグしたブランドカテゴリの更新
          //   RadialApiClient.updatestoreGroup(id, {
          //     index: minIndex + index,
          //   });
          // } else {
          //   // 残りのブランドカテゴリのindex更新（連番にリセット）
          //   RadialApiClient.updatestoreGroup(id, {
          //     index: minIndex + index,
          //   });
          // }
        });
      } else if (oldIndex > newIndex) {
        // 下から上に動かした場合の処理
        const minIndex = storeGroup.stores[newIndex + 1]?.index;
        const maxIndex = storeGroup.stores[newIndex]?.index;
        // 更新にあたって必要十分なブランドカテゴリの取得
        const updatedStores = storeGroup.stores.filter((category) => {
          return category.index >= minIndex && category.index <= maxIndex;
        });
        updatedStores.forEach((updatedCategory, index) => {
          // const id = updatedCategory.storeGroupId;
          // if (index === 0) {
          //   // ドラッグしたブランドカテゴリの更新
          //   RadialApiClient.updatestoreGroup(id, {
          //     index: minIndex,
          //   });
          // } else {
          //   // 残りのブランドカテゴリのindex更新（連番にリセット）
          //   RadialApiClient.updatestoreGroup(id, {
          //     index: minIndex + index,
          //   });
          // }
        });
      }
      setTimeout(() => {
        //   this.getBrandCategories();
      }, 100);
      this.$emit("isLoading", false);
    },
    deleteStoreFromGroupDialog(storeId: string) {
      this.storeIdToDeleteFromGroup = storeId;
      this.dialogDeleteStore = true;
    },
    async deleteStoreFromGroup() {
      const dto = { interstoreTransferGroupId: null };
      if (!this.storeIdToDeleteFromGroup) return;
      await RadialApiClient.updateStore(this.storeIdToDeleteFromGroup, dto as UpdateStoreDto);
      this.storeGroups = await this.getStoreGroups();
      this.dialogDeleteStore = false;
    },
    getFilteredStores(): Store[] {
      const storeIds = this.storeGroups?.flatMap((group) => group.stores?.map((store) => store.id) ?? []) ?? [];
      return this.stores.filter((store) => !storeIds.includes(store.id));
    },
    cancel() {
      this.editingGroupName = null;
      this.dialogCreateGroup = false;
      this.dialogDeleteGroup = false;
      this.dialogDeleteStore = false;
      this.dialogEditGroup = false;
    },
    createGroupDialog() {
      this.newGroupName = null;
      this.dialogCreateGroup = true;
      // this.dialogAddBrand = true;
      // this.newBrandInfo = {
      //   code: null,
      //   name: "",
      // };
    },
    async addStoreToGroup(storeGroup: StoreGroup) {
      this.$emit("isLoading", true);

      // const dto: UpdateStoreDto = { interstoreTransferGroupId: storeGroup.group.id };
      const dto = { interstoreTransferGroupId: storeGroup.group.id };
      if (!this.selectedStoreForGroup) return;
      await RadialApiClient.updateStore(this.selectedStoreForGroup?.id, dto as UpdateStoreDto);
      this.storeGroups = await this.getStoreGroups();
      this.editingGroupName = null;

      this.$emit("isLoading", false);
    },
    menuChanged(isActive: boolean) {
      if (!isActive) {
        this.activeItem = null;
      }
    },
    selectGroup(storeGroup: StoreGroup, index: number) {
      this.selectedGroup = storeGroup;
      if (index === 0) {
        this.newGroupName = this.selectedGroup.group.name;
        this.dialogEditGroup = true;
      } else {
        this.dialogDeleteGroup = true;
      }
    },
    listActive(item: string) {
      if (this.activeItem === item) {
        this.activeItem = null;
      } else {
        this.activeItem = item;
      }
    },
    getStoreGroupLength(items: StoreGroup): number {
      return items.stores.length;
    },
    async getStoreGroups(): Promise<StoreGroup[]> {
      const storeGroups = await RadialApiClient.listInterstoreTransferGroup();
      return (
        storeGroups?.map((group) => {
          return {
            group: group, // group をそのまま返す
            stores:
              group.stores?.map((store, index) => ({
                ...store,
                index: index,
              })) ?? [],
          };
        }) ?? []
      );
    },
    async createGroup() {
      if (!store.state.company?.id || !this.newGroupName) return;
      const dto: CreateInterstoreTransferGroupDto = { companyId: store.state.company?.id, name: this.newGroupName };
      await RadialApiClient.createInterstoreTransferGroup(dto);
      this.storeGroups = await this.getStoreGroups();
      this.newGroupName = null;
      this.dialogCreateGroup = false;
    },
    async editGroup() {
      if (!store.state.company?.id || !this.newGroupName || !this.selectedGroup) return;
      const dto: UpdateInterstoreTransferGroupDto = { companyId: store.state.company?.id, name: this.newGroupName };
      await RadialApiClient.updateInterstoreTransferGroup(this.selectedGroup.group.id, dto);
      this.storeGroups = await this.getStoreGroups();
      this.newGroupName = null;
      this.dialogEditGroup = false;
    },
    async deleteGroup() {
      if (!store.state.company?.id || !this.selectedGroup) return;
      await RadialApiClient.deleteInterstoreTransferGroup(this.selectedGroup.group.id);
      this.storeGroups = await this.getStoreGroups();
      this.dialogDeleteGroup = false;
    },
    createGroupDisabled() {
      if (!this.newGroupName) {
        return true;
      } else if (this.storeGroups.map((group) => group.group.name).includes(this.newGroupName)) {
        return true;
      } else {
        return false;
      }
    },
    getStoreName(id: string | null): string {
      return this.stores.find((store) => store.id === id)?.name ?? "";
    },
  },
  async mounted() {
    this.storeGroups = await this.getStoreGroups();
  },
});
