import axios, { AxiosError } from "axios";
import awsmobile from "@/aws-exports";
import {
  Company,
  Member,
  Product,
  ProductListEntity,
  Store,
  ProductDetailCommentEntity,
  DashboardSalesEntity,
  DashboardSalesByBrandEntity,
  DashboardSalesByCategoryEntity,
  DashboardCommentByStoreEntity,
  DashboardCommentByMemberEntity,
  DashboardSaleAnalysisEntity,
  DashboardProductRankingEntity,
  DashboardSeasonEntity,
  ProductListInputEntity,
  Warehouse,
  ReportCommentEntity,
  ReportSalesProductEntity,
  Comment,
  ProductDetailTrendEntity,
  Sku,
  AuthResult,
  SmaregiStore,
  SmaregiIntegration,
  LogizardIntegration,
  CustomTagMaster,
  CustomTag,
  ReportStoreCommentEntity,
  Season,
  ShopifyIntegration,
  FutureshopIntegration,
  ShopifyStoreEntity,
  OrderProduct,
  Brand,
  Category,
  MdMapStatsEntity,
  MdMapProductEntity,
  OrderSku,
  DeliveryMapEntity,
  SeasonBrandCategoryPlan,
  SalesBuyingPlanTotalEntity,
  SalesBuyingPlanBrandCategoryEntity,
  ProductWithOrderProductsEntity,
  SkuWithOrderSkusEntity,
  Color,
  Size,
  JanCodeLogic,
  BrandCategory,
  ProductFilterInfoEntity,
  BrandWithCategoryInfoEntity,
  ProductDetailBasicInfoEntity,
  Attribute,
  SeasonPlan,
  ProductImage,
  ProductDetailSalesDigestRateChartEntity,
  Service,
  CsvCheckResultEntity,
  CsvUploadResultEntity,
  CustomIntegration,
  Alert,
  AlertSetting,
  AlertMaster,
  BudgetWeekRate,
  UpsertOrderSkuFromExistingOrderProductEntity,
  StoreActualsDailyReportEntity,
  StoreAnalysis,
  StoreActualsRankingSaleEntity,
  StoreActualsRankingOtherEntity,
  StoreActualsGraphEntity,
  DailyStoreBudgetEntity,
  StatusResultEntity,
  ImageCheckResultEntity,
  SkuDetailTrendEntity,
  GroupListGraphItemEntity,
  SeasonsSummaryEntity,
  AdminUser,
  AdminUserMaster,
  GetDetailImagesEntity,
  Factory,
  GetMainImagesEntity,
  BaseIntegration,
  StoreDailyPlan,
  OrderInstruction,
  StoreSeasonPlanTotalEntity,
  StoreSeasonPlanSeasonTotalEntity,
  StoreSeasonPlanStoreEntity,
  MdMapSeasonTotalBuyingInfoEntity,
  MdMapSeasonTotalItemRatioEntity,
  MdMapSeasonTotalGroupListEntity,
  ActionListSearchResultEntity,
  CustomActionMaster,
  Action,
  ProductListActionAddProductsDto,
  SaleActionDetail,
  CustomActionDetail,
  OrderInstructionSearchResultEntity,
  OrderProductSearchResultEntity,
  OrderInstructionDetailEntity,
  MdMapDeliveryProductEntity,
  MdMapAdditionalProductEntity,
  MdMapStatsDelioveryAdditionalEntity,
  SalesBuyingPlanMonthBudgetTotalRateEntity,
  MakeshopIntegration,
  SeasonBrandCategory,
  ItemNumberLogic,
  CsvProductNumbersEntity,
  ProductCsvEntity,
  SkuCsvEntity,
  SmaregiSkuCsvEntity,
  ShopifySkuCsvDataEntity,
  CsvItemSkuCheckResultEntity,
  SkuListEntity,
  ItemNumberLatestEntity,
  ShopifyCustomization,
  ActionRequestSearchResultEntity,
  ActionListSkuDetailStockActionEntity,
  StockActionDetail,
  S3KeySkuMainEntity,
  NextEngineIntegration,
  ProductDetailActionInfoEntity,
  SmaregiOption,
  SmaregiFreeLabel,
  SmaregiSkuCsvDataEntity,
  SeasonDeleteInfoEntity,
  ShopifyOption,
  SkuFilterInfoEntity,
  ProductCustomValueSetting,
  SkuCustomValueSetting,
  ProductCustomValue,
  SkuCustomValue,
  ProductCsvDataEntity,
  SkuCsvDataEntity,
  CsvSaleNumbersEntity,
  CsvStockNumbersEntity,
  CsvSaleStockDownloadEntity,
  ActionListCustomActionEntity,
  ActionListProductSaleActionDetailEntity,
  ResultDownloadEntity,
  IndividualAlertSetting,
  InterstoreTransferGroup,
  InterstoreTransferActionDetail,
  ActionListInterstoreTransferActionDetailEntity
} from "./entities";
import * as dto from "./dto";
import Auth from "@aws-amplify/auth";

export default class RadialApiClient {
  private static readonly maxRetries: number = 5;

  static notNull<T>(item: T | null | undefined): item is T {
    return item !== null && item !== undefined;
  }

  static get rawEnv(): string {
    return awsmobile.aws_user_files_s3_bucket.split("-")[1];
  }

  static get rawUrl(): string {
    switch (this.rawEnv) {
      case "master":
        return "https://radial-db.com";
      case "staging":
        return "https://stg.radial-db.com";
      case "develop":
        return "https://dev.radial-db.com";
      case "sprint":
        return "https://sprint.radial-db.com";
      default:
        return "http://localhost:8080";
    }
  }

  static get env(): string {
    if (window.location.href.startsWith("http://localhost")) {
      return "localhost";
    }
    return this.rawEnv;
  }

  static async getAccessToken(): Promise<string | null> {
    const session = await Auth.currentSession();
    if (session) {
      return session.getAccessToken().getJwtToken();
    }
    return null;
  }

  static get apiUrl(): string {
    /* Uncomment below line if you want to connect to your local server */
    // if (this.env === "localhost") return "http://localhost:3000";
    switch (this.rawEnv) {
      case "master":
        return "https://api.radial-db.com";
      case "staging":
        return "https://stg-api.radial-db.com";
      case "develop":
        return "https://dev-api.radial-db.com";
      default: // case "sprint":
        return "https://sprint-api.radial-db.com";
    }
  }

  /* Common functions */
  private static async get<S, T>(path: string, data: S): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      try {
        const accessToken = await this.getAccessToken();
        const response = await axios.get(`${this.apiUrl}${this.replaceHash(path)}`, {
          params: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response && response.status < 400) {
          return resolve(response.data as T | null);
        } else {
          throw new Error(`status code is ${response.status}`);
        }
      } catch (error: unknown) {
        reject(error);
      }
    });
  }

  private static async post<S, T>(path: string, data: S): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      try {
        const accessToken = await this.getAccessToken();
        const response = await axios.post(`${this.apiUrl}${this.replaceHash(path)}`, data, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response && response.status < 400) {
          resolve(response.data as T | null);
        } else {
          throw new Error(`status code is ${response.status}`);
        }
      } catch (error: unknown) {
        reject(error);
      }
    });
  }

  private static async patch<S, T>(path: string, data: S): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      try {
        const accessToken = await this.getAccessToken();
        const response = await axios.patch(`${this.apiUrl}${this.replaceHash(path)}`, data, {
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response && response.status < 400) {
          resolve(response.data as T | null);
        } else {
          throw new Error(`status code is ${response.status}`);
        }
      } catch (error: unknown) {
        reject(error);
      }
    });
  }

  private static async delete<S, T>(path: string, data: S): Promise<T | null> {
    return new Promise<T | null>(async (resolve, reject) => {
      try {
        const accessToken = await this.getAccessToken();
        const response = await axios.delete(`${this.apiUrl}${this.replaceHash(path)}`, {
          params: data,
          headers: {
            authorization: `Bearer ${accessToken}`,
          },
        });
        if (response && response.status < 400) {
          resolve(response.data as T | null);
        } else {
          throw new Error(`status code is ${response.status}`);
        }
      } catch (error: unknown) {
        reject(error);
      }
    });
  }

  static replaceHash(text: string): string {
    return text.replaceAll("#", "%23");
  }
  /* ----------------- */

  // Company
  static async createCompany(dto: dto.CreateCompanyDto): Promise<Company | null> {
    return await this.post("/company", dto);
  }

  static async updateCompany(id: string, dto: dto.UpdateCompanyDto): Promise<Company | null> {
    return await this.patch(`/company/${id}`, dto);
  }

  static async getCompany(id: string): Promise<Company | null> {
    return await this.get(`/company/${id}`, {});
  }

  static async listComments(storeId?: string, servedAt?: string, productId?: string): Promise<Comment[] | null> {
    return await this.get("/comment", {
      storeId: storeId,
      servedAt: servedAt,
      productId: productId,
    });
  }

  static async createComment(dto: dto.CreateCommentDto): Promise<Comment | null> {
    return await this.post("/comment", dto);
  }

  static async updateComment(id: string, dto: dto.UpdateCommentDto): Promise<Comment | null> {
    return await this.patch(`/comment/${id}`, dto);
  }

  static async deleteComment(id: string): Promise<Comment | null> {
    return await this.delete(`/comment/${id}`, []);
  }

  // StoreActuals
  static async updateStoreAnalysis(id: string, dto: dto.UpdateStoreAnalysisDto): Promise<StoreAnalysis | null> {
    return await this.patch(`/store-analysis/${id}`, dto);
  }

  static async listStoreAnalysis(storeId?: string, date?: string): Promise<StoreAnalysis[] | null> {
    return await this.get("/store-analysis", { storeId: storeId, date: date });
  }

  static async getStoreAnalysis(id: string): Promise<StoreAnalysis | null> {
    return await this.get(`/store-analysis/${id}`, []);
  }

  // Store
  static async createStore(dto: dto.CreateStoreDto): Promise<Store | null> {
    return await this.post("/store", dto);
  }

  static async getStore(id: string): Promise<Store | null> {
    return await this.get(`/store/${id}`, {});
  }

  static async updateStore(id: string, dto: dto.UpdateStoreDto): Promise<Store | null> {
    return await this.patch(`/store/${id}`, dto);
  }

  static async deleteStore(id: string): Promise<Store | null> {
    return await this.delete(`/store/${id}`, {});
  }

  // InterstoreTransferGroup
  static async createInterstoreTransferGroup(dto: dto.CreateInterstoreTransferGroupDto): Promise<InterstoreTransferGroup | null> {
    return await this.post("/interstore-transfer-group", dto);
  }

  static async listInterstoreTransferGroup(): Promise<InterstoreTransferGroup[] | null> {
    return await this.get(`/interstore-transfer-group`, {});
  }

  static async getInterstoreTransferGroup(id: string): Promise<InterstoreTransferGroup | null> {
    return await this.get(`/interstore-transfer-group/${id}`, {});
  }

  static async updateInterstoreTransferGroup(id: string, dto: dto.UpdateInterstoreTransferGroupDto): Promise<InterstoreTransferGroup | null> {
    return await this.patch(`/interstore-transfer-group/${id}`, dto);
  }

  static async deleteInterstoreTransferGroup(id: string): Promise<InterstoreTransferGroup | null> {
    return await this.delete(`/interstore-transfer-group/${id}`, {});
  }

  // Member
  static async createMember(dto: dto.CreateMemberDto): Promise<Member | null> {
    return await this.post("/member", dto);
  }

  static async updateMember(id: string, dto: dto.UpdateMemberDto): Promise<Member | null> {
    return await this.patch(`/member/${id}`, dto);
  }

  static async deleteMember(id: string): Promise<Member | null> {
    return await this.delete(`/member/${id}`, {});
  }

  static async listMembers(userSub: string): Promise<Member[] | null> {
    return await this.get(`/member/store/${userSub}`, {});
  }
  static async getMembers(): Promise<Member[] | null> {
    return await this.get("/member", {});
  }

  // static async listMembers(storeId: string): Promise<Member[] | null> {
  //   return await this.get('/members', { storeId });
  // }

  // Product
  static async createProduct(dto: dto.CreateProductDto): Promise<Product | null> {
    return await this.post("/product", dto);
  }

  static async getProduct(id: string): Promise<Product | null> {
    return await this.get(`/product/${id}`, {});
  }

  static async updateProduct(id: string, dto: dto.UpdateProductDto): Promise<Product | null> {
    return await this.patch(`/product/${id}`, dto);
  }

  static async deleteProduct(id: string): Promise<Product | null> {
    return await this.delete(`/product/${id}`, {});
  }

  static async listProducts(
    seasonId?: number | null,
    brandId?: number | null,
    categoryId?: number | null
  ): Promise<Product[] | null> {
    return await this.get("/product", {
      seasonId,
      brandId,
      categoryId,
    });
  }

  // static async getProductByCompanyIdAndItemNumber(companyId: string, itemNumber: string) {
  //   return await this.get('/product', { companyId, itemNumber });
  // }

  // ProductImage
  static async createProductImage(dto: dto.CreateProductImageDto): Promise<ProductImage | null> {
    return await this.post("/product-image", dto);
  }
  static async deleteProductImage(id: string): Promise<ProductImage | null> {
    return await this.delete(`/product-image/${id}`, {});
  }

  // Sku
  static async listSkus(): Promise<Sku[] | null> {
    return await this.get("/sku", {});
  }
  static async getSku(id: string): Promise<Sku | null> {
    return await this.get(`/sku/${id}`, {});
  }
  static async listSkusByProduct(id: string): Promise<Sku[] | null> {
    return await this.get(`/sku/product/${id}`, {});
  }
  static async createSku(dto: dto.CreateSkuDto): Promise<Sku | null> {
    return await this.post("/bff/season/md-map/sku", dto);
  }
  static async updateSku(id: string, dto: dto.UpdateSkuDto): Promise<Sku | null> {
    return await this.patch(`/bff/season/md-map/sku/${id}`, dto);
  }
  static async deleteSku(id: string): Promise<Sku | null> {
    return await this.delete(`/sku/${id}`, {});
  }

  // Store
  static async listStores(): Promise<Store[] | null> {
    return await this.get("/store", {});
  }

  // AlertMaster
  static async listAlertMasters(): Promise<AlertMaster[] | null> {
    return await this.get(`/alert-master`, {});
  }

  // Alert
  static async listAlerts(): Promise<Alert[] | null> {
    return await this.get("/alert", {});
  }

  static async deleteAlert(id: string): Promise<Alert | null> {
    return await this.delete(`/alert/${id}`, {});
  }

  static async updateAlert(id: string, dto: dto.UpdateAlertDto): Promise<Alert | null> {
    return await this.patch(`/alert/${id}`, dto);
  }

  static async userDeleteAlert(id: string): Promise<Alert | null> {
    return await this.delete(`/alert/userdelete/${id}`, {});
  }

  static async listAlertsByProduct(id: string): Promise<Alert[] | null> {
    return await this.get(`/alert/product/${id}`, {});
  }

  // IndividualAlertSetting
  static async createIndividualAlertSetting(dto: dto.CreateIndividualAlertSettingDto): Promise<IndividualAlertSetting | null> {
    return await this.post("/individual-alert-setting", dto);
  }
  static async deleteIndividualAlertSetting(id: string): Promise<IndividualAlertSetting | null> {
    return await this.delete(`/individual-alert-setting/${id}`, {});
  }
  static async getIndividualAlertSettingByProduct(id: string): Promise<IndividualAlertSetting[] | null> {
    return await this.get(`/individual-alert-setting/product/${id}`, {});
  }
  static async updateIndividualAlertSetting(id: string, dto: dto.UpdateIndividualAlertSettingDto): Promise<IndividualAlertSetting | null> {
    return await this.patch(`/individual-alert-setting/${id}`, dto);
  }


  // AlertSetting(旧TagMaster)
  static async createAlertSetting(): Promise<AlertSetting[] | null> {
    return await this.post("/alert-setting/init", {});
  }

  static async listAlertSettings(): Promise<AlertSetting[] | null> {
    return await this.get("/alert-setting", {});
  }

  static async updateAlertSetting(id: string, dto: dto.UpdateAlertSettingDto): Promise<AlertSetting | null> {
    return await this.patch(`/alert-setting/${id}`, dto);
  }

  // Warehouse
  static async listWarehouses(): Promise<Warehouse[] | null> {
    return await this.get("/warehouse", {});
  }
  static async updateWarehouse(id: string, dto: dto.UpdateWarehouseDto): Promise<Warehouse | null> {
    return await this.patch(`/warehouse/${id}`, dto);
  }
  static async createWarehouse(dto: dto.CreateWarehouseDto): Promise<Warehouse | null> {
    return await this.post("/warehouse", dto);
  }
  static async deleteWarehouse(id: string): Promise<Warehouse | null> {
    return await this.delete(`/warehouse/${id}`, {});
  }

  // Factory
  static async listFactories(): Promise<Factory[] | null> {
    return await this.get("/factory", {});
  }
  static async updateFactory(id: string, dto: dto.UpdateFactoryDto): Promise<Factory | null> {
    return await this.patch(`/factory/${id}`, dto);
  }
  static async createFactory(dto: dto.CreateFactoryDto): Promise<Factory | null> {
    return await this.post("/factory", dto);
  }
  static async deleteFactory(id: string): Promise<Factory | null> {
    return await this.delete(`/factory/${id}`, {});
  }

  // CustomTagMaster
  static async createCustomTagMaster(dto: dto.CreateCustomTagMasterDto): Promise<CustomTagMaster | null> {
    return await this.post("/custom-tag-master", dto);
  }

  static async listCustomTagMasters(): Promise<CustomTagMaster[] | null> {
    return await this.get("/custom-tag-master", {});
  }

  static async updateCustomTagMaster(id: number, dto: dto.UpdateCustomTagMasterDto): Promise<CustomTagMaster | null> {
    return await this.patch(`/custom-tag-master/${id}`, dto);
  }

  static async deleteCustomTagMaster(id: number): Promise<CustomTagMaster | null> {
    return await this.delete(`/custom-tag-master/${id}`, {});
  }

  // CustomTag
  static async createCustomTag(dto: dto.CreateCustomTagDto): Promise<CustomTag | null> {
    return await this.post("/custom-tag", dto);
  }

  static async deleteCustomTag(id: string): Promise<CustomTag | null> {
    return await this.delete(`/custom-tag/${id}`, {});
  }

  static async listProductCustomTags(
    itemNumber?: string | null,
    productId?: string | null
  ): Promise<CustomTag[] | null> {
    return await this.get("/custom-tag/product", {
      itemNumber,
      productId,
    });
  }

  // Season
  static async listSeasons(): Promise<Season[] | null> {
    return await this.get("/season", {});
  }
  static async updateSeason(id: number, dto: dto.UpdateSeasonDto): Promise<Season | null> {
    return await this.patch(`/season/${id}`, dto);
  }
  static async getSeason(id: number): Promise<Season | null> {
    return await this.get(`/season/${id}`, {});
  }
  static async createSeason(dto: dto.CreateSeasonDto): Promise<Season | null> {
    return await this.post("/season", dto);
  }
  static async deleteSeason(id: number): Promise<Season | null> {
    return await this.delete(`/season/${id}`, {});
  }
  static async getSeasonDeleteInfo(id: number): Promise<SeasonDeleteInfoEntity | null> {
    return await this.get(`/season/deleteInfo/${id}`, {});
  }

  //Seasons
  static async listSeasonSummaries(): Promise<SeasonsSummaryEntity[] | null> {
    return await this.get("/bff/seasons", {});
  }

  // SeasonPlan
  static async listSeasonPlan(): Promise<SeasonPlan[] | null> {
    return await this.get("/season-plan", {});
  }
  static async updateSeasonPlan(id: number, dto: dto.UpdateSeasonPlanDto): Promise<SeasonPlan | null> {
    return await this.patch(`/season-plan/${id}`, dto);
  }

  // Brand
  static async listBrands(): Promise<Brand[] | null> {
    return await this.get("/brand", {});
  }
  static async updateBrand(id: number, dto: dto.UpdateBrandDto): Promise<Brand | null> {
    return await this.patch(`/brand/${id}`, dto);
  }
  static async createBrand(dto: dto.CreateBrandDto): Promise<Brand | null> {
    return await this.post("/brand", dto);
  }
  static async deleteBrand(id: number): Promise<Brand | null> {
    return await this.delete(`/brand/${id}`, {});
  }

  // Category
  static async listCategories(): Promise<Category[] | null> {
    return await this.get("/category", {});
  }
  static async updateCategory(id: number, dto: dto.UpdateCategoryDto): Promise<Category | null> {
    return await this.patch(`/category/${id}`, dto);
  }
  static async createCategory(dto: dto.CreateCategoryDto): Promise<Category | null> {
    return await this.post("/category", dto);
  }
  static async deleteCategory(id: number): Promise<Category | null> {
    return await this.delete(`/category/${id}`, {});
  }

  // Attribute
  static async listAttributes(): Promise<Attribute[] | null> {
    return await this.get("/attribute", {});
  }
  static async updateAttribute(id: string, dto: dto.UpdateAttributeDto): Promise<Attribute | null> {
    return await this.patch(`/attribute/${id}`, dto);
  }
  static async createAttribute(dto: dto.CreateAttributeDto): Promise<Attribute | null> {
    return await this.post("/attribute", dto);
  }
  static async deleteAttribute(id: string): Promise<Attribute | null> {
    return await this.delete(`/attribute/${id}`, {});
  }

  // Color
  static async getColor(id: string): Promise<Color | null> {
    return await this.get(`/color/${id}`, {});
  }
  static async listColors(): Promise<Color[] | null> {
    return await this.get("/color", {});
  }
  static async updateColor(id: string, dto: dto.UpdateColorDto): Promise<Color | null> {
    return await this.patch(`/color/${id}`, dto);
  }
  static async createColor(dto: dto.CreateColorDto): Promise<Color | null> {
    return await this.post("/color", dto);
  }
  static async deleteColor(id: string): Promise<Color | null> {
    return await this.delete(`/color/${id}`, {});
  }

  // Size
  static async getSize(id: string): Promise<Size | null> {
    return await this.get(`/size/${id}`, {});
  }
  static async listSizes(): Promise<Size[] | null> {
    return await this.get("/size", {});
  }
  static async updateSize(id: string, dto: dto.UpdateSizeDto): Promise<Size | null> {
    return await this.patch(`/size/${id}`, dto);
  }
  static async createSize(dto: dto.CreateSizeDto): Promise<Size | null> {
    return await this.post("/size", dto);
  }
  static async deleteSize(id: string): Promise<Size | null> {
    return await this.delete(`/size/${id}`, {});
  }

  // JanCodeLogic
  static async listJanCodeLogic(): Promise<JanCodeLogic[] | null> {
    return await this.get(`/jan-code-logic`, {});
  }
  static async updateJancodeLogic(id: number, dto: dto.UpdateJanCodeLogicDto): Promise<JanCodeLogic | null> {
    return await this.patch(`/jan-code-logic/${id}`, dto);
  }

  // Service
  static async listServices(): Promise<Service[] | null> {
    return await this.get("/service", {});
  }

  // BrandCategory
  static async listBrandCategories(): Promise<BrandCategory[] | null> {
    return await this.get("/brand-category", {});
  }
  static async updateBrandCategory(id: string, dto: dto.UpdateBrandCategoryDto): Promise<BrandCategory | null> {
    return await this.patch(`/brand-category/${id}`, dto);
  }
  static async createBrandCategory(dto: dto.CreateBrandCategoryDto): Promise<BrandCategory | null> {
    return await this.post("/brand-category", dto);
  }
  static async deleteBrandCategory(id: string): Promise<BrandCategory | null> {
    return await this.delete(`/brand-category/${id}`, {});
  }
  static async listBrandWithCategoryInfo(): Promise<BrandWithCategoryInfoEntity[] | null> {
    return await this.get("/brand-category/by-brand", {});
  }

  // OrderProduct
  static async listOrderProducts(
    productId?: string | null,
    seasonId?: number | null,
    yearMonth?: string | null
  ): Promise<OrderProduct[] | null> {
    return await this.get("/order-product", {
      productId,
      seasonId,
      yearMonth,
    });
  }
  static async createOrderProduct(dto: dto.CreateOrderProductDto): Promise<OrderProduct | null> {
    return await this.post("/order-product", dto);
  }
  static async deleteOrderProduct(id: number): Promise<OrderProduct | null> {
    return await this.delete(`/order-product/${id}`, {});
  }

  static async updateOrderProduct(id: number, dto: dto.UpdateOrderProductDto): Promise<OrderProduct | null> {
    return await this.patch(`/order-product/${id}`, dto);
  }

  // OrderSku
  static async listOrderSkus(
    productId?: string | null,
    seasonId?: number | null,
    yearMonth?: string | null
  ): Promise<OrderSku[] | null> {
    return await this.get("/order-sku", {
      productId,
      seasonId,
      yearMonth,
    });
  }

  static async getAttribute(n: number): Promise<Attribute | null> {
    return await this.get(`/attribute/${n}`, {});
  }

  // SeasonBrandCategoryPlan
  static async updateSeasonBrandCategoryPlan(
    id: string,
    dto: dto.UpdateSeasonBrandCategoryPlanDto
  ): Promise<SeasonBrandCategoryPlan | null> {
    return await this.patch(`/season-brand-category-plan/${id}`, dto);
  }

  // SeasonBrandCategory
  static async updateSeasonBrandCategory(
    id: number,
    dto: dto.UpdateSeasonBrandCategoryDto
  ): Promise<SeasonBrandCategory | null> {
    return await this.patch(`/season-brand-category/${id}`, dto);
  }

  // Jan Code
  static async getNewJanCode(): Promise<string | null> {
    return await this.get("/jan-code-logic/new", {});
  }

  // Item Number Logic
  static async getItemNumberLogic(): Promise<ItemNumberLogic | null> {
    return await this.get("/item-number-logic", {});
  }

  static async createItemNumberLogic(dto: dto.CreateItemNumberLogicDto): Promise<ItemNumberLogic | null> {
    return await this.post("/item-number-logic", dto);
  }

  static async updateItemNumberLogic(dto: dto.UpdateItemNumberLogicCustomDto): Promise<ItemNumberLogic | null> {
    return await this.patch("/item-number-logic", dto);
  }

  // Item Number Latest
  static async getLatestItemNumber(dto: dto.LatestItemNumberDto): Promise<ItemNumberLatestEntity | null> {
    return await this.post("/bff/item-number/latest", dto);
  }

  // Dashboard
  static async listDashboardSales(start: string, end: string): Promise<DashboardSalesEntity[] | null> {
    return await this.get("/bff/dashboard/sales", {
      start: start,
      end: end,
    });
  }

  static async listDashboardSalesByBrand(start: string, end: string): Promise<DashboardSalesByBrandEntity[] | null> {
    return await this.get("/bff/dashboard/sales/brand", {
      start: start,
      end: end,
    });
  }

  static async listDashboardSalesByCategory(
    start: string,
    end: string
  ): Promise<DashboardSalesByCategoryEntity[] | null> {
    return await this.get("/bff/dashboard/sales/category", {
      start: start,
      end: end,
    });
  }

  static async listDashboardCommentsByStore(
    start: string,
    end: string
  ): Promise<DashboardCommentByStoreEntity[] | null> {
    return await this.get("/bff/dashboard/comments/store", {
      start: start,
      end: end,
    });
  }

  static async listDashboardCommentsByMember(
    start: string,
    end: string
  ): Promise<DashboardCommentByMemberEntity[] | null> {
    return await this.get("/bff/dashboard/comments/member", {
      start: start,
      end: end,
    });
  }

  static async getDashboardChart(params: dto.DashboardSaleAnalysisDto): Promise<DashboardSaleAnalysisEntity | null> {
    return await this.post("/bff/dashboard/sale-analysis", {
      start: params.start,
      end: params.end,
      seasonIds: params.seasonIds,
      brandIds: params.brandIds,
      categoryIds: params.categoryIds,
      storeIds: params.storeIds,
    });
  }

  static async getDashboardRanking(
    params: dto.DashboardProductRankingDto
  ): Promise<DashboardProductRankingEntity[] | null> {
    return await this.post("/bff/dashboard/product-ranking", {
      start: params.start,
      end: params.end,
      storeIds: params.storeIds,
      limit: params.limit,
    });
  }

  static async listDashboardSeasons(): Promise<DashboardSeasonEntity[] | null> {
    return await this.post("/bff/dashboard/seasons", {});
  }

  // ProductList 検索
  static async listProductList(input: ProductListInputEntity): Promise<ProductListEntity | null> {
    return await this.post("/bff/product-list/search", input);
  }
  // ProductList フィルター条件
  static async getProductFilterInfo(): Promise<ProductFilterInfoEntity | null> {
    return await this.get("/bff/product-list/filter-info", null);
  }
  // ProductList カスタムタグ一括付与
  static async addCustomTags(dto: dto.CustomTagsToProductsDto) {
    return await this.post("/custom-tag/products", dto);
  }
  static async addActionToProducts(dto: ProductListActionAddProductsDto) {
    return await this.post("/bff/product-list/action/add-products", dto);
  }

  // SkuList
  // SkuList フィルター条件
  static async getSkuFilterInfo(): Promise<SkuFilterInfoEntity | null> {
    return await this.get("/bff/sku-list/filter-info", null);
  }
  static async listSkuList(input: dto.SkuListInputDto): Promise<SkuListEntity | null> {
    return await this.post("/bff/sku-list/search", input);
  }
  static async addActionToSkus(dto: dto.SkuListActionAddSkusDto) {
    return await this.post("/bff/sku-list/action/add-skus", dto);
  }

  // ActualResultDownload
  static async downloadProductActualResultCsv(dto: dto.ResultDownloadProductDto): Promise<ResultDownloadEntity | null> {
    return await this.post("/bff/result-download/product", dto);
  }
  static async downloadSkuActualResultCsv(dto: dto.ResultDownloadSkuDto): Promise<ResultDownloadEntity | null> {
    return await this.post("/bff/result-download/sku", dto);
  }

  // ProductDetail
  static async listProductDetailComments(productId: string): Promise<ProductDetailCommentEntity[] | null> {
    return await this.get("/bff/product-detail/comment", {
      productId,
    });
  }
  static async getProductDetailBasicInfo(productId: string): Promise<ProductDetailBasicInfoEntity | null> {
    return await this.get("/bff/product-detail/basic-info", {
      productId,
    });
  }
  static async updateProductDetailBasicInfo(id: string, dto: dto.UpdateProductBasicInfoDto) {
    return await this.patch(`/bff/product-detail/basic-info/${id}`, dto);
  }
  static async getProductDetailTrends(productId: string): Promise<ProductDetailTrendEntity | null> {
    return await this.get("/bff/product-detail/trend/table/product", {
      productId,
    });
  }
  static async getSkuDetailTrends(skuId: string): Promise<SkuDetailTrendEntity | null> {
    return await this.get("/bff/product-detail/trend/table/sku", {
      skuId,
    });
  }
  static async getProductDetailTrendChartsValue(
    productId: string,
    startIndex: number,
    endIndex: number,
    period: number
  ): Promise<ProductDetailSalesDigestRateChartEntity | null> {
    return await this.get("/bff/product-detail/trend/graph", {
      productId,
      startIndex,
      endIndex,
      period,
    });
  }
  static async getProductDetailActionList(productId: string): Promise<ProductDetailActionInfoEntity | null> {
    return await this.get("/bff/product-detail/action-info", { productId });
  }

  // StoreActuals 概要
  static async listStoreActualsDailyReport(date: string): Promise<StoreActualsDailyReportEntity | null> {
    return await this.get("/bff/store-actuals/daily-report", {
      date: date,
    });
  }

  // StoreActuals コメント概要
  static async getReportComment(date: string, id: string): Promise<ReportCommentEntity | null> {
    return await this.get("/bff/store-actuals/comment", {
      date: date,
      storeId: id,
    });
  }

  // StoreActuals コメントリスト
  static async listReportComments(date: string, id: string): Promise<ReportStoreCommentEntity[] | null> {
    return await this.get("/bff/store-actuals/store-comment", {
      date: date,
      storeId: id,
    });
  }

  // StoreActuals 売上商品
  static async listReportSalesProducts(date: string, id: string): Promise<ReportSalesProductEntity[] | null> {
    return await this.get("/bff/store-actuals/sales-product", {
      date: date,
      storeId: id,
    });
  }

  // StoreActuals 日別店舗予算
  static async getStoreDailyBudget(
    storeId: string,
    year: string,
    month: string,
    day: string
  ): Promise<StoreDailyPlan | null> {
    return await this.get(`/store-daily-plan/store`, {
      storeId: storeId,
      year: year,
      month: month,
      day: day,
    });
  }

  // StoreActuals グラフ
  static async getStoreActualsGraph(
    start: string,
    end: string,
    storeId: string
  ): Promise<StoreActualsGraphEntity | null> {
    return await this.get("/bff/store-actuals/graph", {
      start,
      end,
      storeId,
    });
  }

  // StoreActuals 店舗別売上実績ランキング
  static async listStoreActualsSaleRanking(start: string, end: string): Promise<StoreActualsRankingSaleEntity | null> {
    return await this.get("/bff/store-actuals/ranking/sale", {
      start,
      end,
    });
  }

  // StoreActuals 店舗別その他ランキング
  static async listStoreActualsOtherRanking(
    start: string,
    end: string
  ): Promise<StoreActualsRankingOtherEntity | null> {
    return await this.get("/bff/store-actuals/ranking/other", {
      start,
      end,
    });
  }

  // csv アップロード
  static async getCsvProductNumbers(dto: dto.CsvConditionDto): Promise<CsvProductNumbersEntity | null> {
    return await this.post("/bff/csv/item-sku/numbers", dto);
  }

  static async downloadProductCsv(dto: dto.CsvConditionDto): Promise<ProductCsvDataEntity | null> {
    return await this.post("/bff/csv/item-sku/download/product", dto);
  }

  static async downloadSkuCsv(dto: dto.CsvConditionDto): Promise<SkuCsvDataEntity | null> {
    return await this.post("/bff/csv/item-sku/download/sku", dto);
  }

  static async downloadSmaregiSkuCsv(dto: dto.CsvConditionDto): Promise<SmaregiSkuCsvDataEntity | null> {
    return await this.post("/bff/csv/item-sku/download/smaregi", dto);
  }

  static async downloadShopifySkuCsv(dto: dto.CsvConditionDto): Promise<ShopifySkuCsvDataEntity | null> {
    return await this.post("/bff/csv/item-sku/download/shopify", dto);
  }

  static async deleteProductFromCsvPage(dto: dto.CsvConditionDto) {
    return await this.patch("/bff/csv/item-sku/delete/product", dto);
  }

  static async deleteSkuFromCsvPage(dto: dto.CsvConditionDto) {
    return await this.patch("/bff/csv/item-sku/delete/sku", dto);
  }

  /*
  static async uploadCsv(isItem: boolean, key: string): Promise<CsvUploadEntity[] | null> {
    return await this.post("/bff/csv/item-sku/upload", {
      csv_type: isItem ? "item" : "sku",
      s3_key: key,
      env: this.env
    });
  }

  static async downloadCsv(): Promise<ProductDetailBasicInfoEntity[] | null> {
    return await this.get("/bff/csv/item-sku/download", {});
  }
  */

  static async uploadItemSkuCsv(key: string): Promise<CsvCheckResultEntity[] | null> {
    return await this.post("/bff/csv/item-sku/upload", {
      s3Key: key,
    });
  }

  static async downloadItemSkuCsv(): Promise<ProductDetailBasicInfoEntity[] | null> {
    return await this.get("/bff/csv/item-sku/download", {});
  }

  static async checkItemSkuCsv(key: string): Promise<CsvItemSkuCheckResultEntity | null> {
    return await this.post("/bff/csv/item-sku/check", {
      s3Key: key,
    });
  }

  // sale and stock csv アップロード
  static async checkSaleStockCsv(key: string): Promise<CsvCheckResultEntity | null> {
    return await this.post("/bff/csv/sale-stock/check", {
      s3Key: key,
    });
  }

  static async uploadSaleStockCsv(key: string): Promise<CsvUploadResultEntity | null> {
    return await this.post("/bff/csv/sale-stock/upload", {
      s3Key: key,
    });
  }

  static async downloadSaleStockCsv(): Promise<ProductDetailBasicInfoEntity[] | null> {
    return await this.get("/bff/csv/sale-stock/download", {});
  }

  static async getSaleCsvNumber(dto: dto.CsvSaleDto): Promise<CsvSaleNumbersEntity | null> {
    return await this.post("/bff/csv/sale-stock/numbers/sale", dto);
  }

  static async getStockCsvNumber(): Promise<CsvStockNumbersEntity | null> {
    return await this.post("/bff/csv/sale-stock/numbers/stock", {});
  }

  static async getSaleCsvS3Key(dto: dto.CsvSaleDto): Promise<CsvSaleStockDownloadEntity | null> {
    return await this.post("/bff/csv/sale-stock/download/sale", dto);
  }

  static async getStockCsvS3Key(): Promise<CsvSaleStockDownloadEntity | null> {
    return await this.post("/bff/csv/sale-stock/download/stock", {});
  }

  static async deleteSaleCsv(dto: dto.CsvSaleDto): Promise<null> {
    return await this.post("/bff/csv/sale-stock/delete/sale", dto);
  }



  // MdMap
  static async listMdMapSeasonTotalBuyingInfoEntity(
    seasonId: number,
    brandIds?: string | null,
    categoryIds?: string | null
  ): Promise<MdMapSeasonTotalBuyingInfoEntity[] | null> {
    return await this.get("/bff/season/md-map/total/buyingInfo", {
      seasonId,
      brandIds,
      categoryIds,
    });
  }

  static async createMdMapProduct(dto: dto.CreateProductDto): Promise<Product | null> {
    return await this.post("/bff/season/md-map/product", dto);
  }

  static async getMdMapSeasonTotalItemRatioEntity(
    dto: dto.MdMapSeasonTotalItemRatioDto
  ): Promise<MdMapSeasonTotalItemRatioEntity | null> {
    return await this.post("/bff/season/md-map/total/item-ratio", dto);
  }

  static async listMdMapSeasonTotalGroupListEntity(
    groupBy: string,
    dto: dto.MdMapSeasonTotalGroupListDto
  ): Promise<MdMapSeasonTotalGroupListEntity[] | null> {
    return await this.post(`/bff/season/md-map/total/group-list/${groupBy}`, dto);
  }

  static async listMdMapStatsEntities(
    seasonId: number,
    yearMonth: string,
    brandIds?: string | null,
    categoryIds?: string | null
  ): Promise<MdMapStatsEntity[] | null> {
    return await this.get("/bff/season/md-map/stats", {
      seasonId,
      yearMonth,
      brandIds,
      categoryIds,
    });
  }

  static async getMdMapStatsTotalEntity(
    seasonId: number,
    yearMonth: string
  ): Promise<MdMapStatsDelioveryAdditionalEntity | null> {
    return await this.get("/bff/season/md-map/stats/total", {
      seasonId,
      yearMonth,
    });
  }

  static async listMdMapDeliveryProductEntities(
    seasonId: number,
    yearMonth: string,
    brandIds?: string | null,
    categoryIds?: string | null
  ): Promise<MdMapDeliveryProductEntity[] | null> {
    return await this.get("/bff/season/md-map/products/delivery", {
      seasonId,
      yearMonth,
      brandIds,
      categoryIds,
    });
  }

  static async listMdMapAdditionalProductEntities(
    seasonId: number,
    yearMonth: string,
    brandIds?: string | null,
    categoryIds?: string | null
  ): Promise<MdMapAdditionalProductEntity[] | null> {
    return await this.get("/bff/season/md-map/products/additional", {
      seasonId,
      yearMonth,
      brandIds,
      categoryIds,
    });
  }

  static async createMdMapOrderProductFromExistingProduct(
    dto: dto.CreateOrderProductFromExistingProductInMdMapDto
  ): Promise<ProductWithOrderProductsEntity | null> {
    return await this.post("/bff/season/md-map/product/existing", dto);
  }

  static async createMdMapOrderSkuFromScratch(
    dto: dto.CreateOrderSkuFromScratchInMdMapDto
  ): Promise<SkuWithOrderSkusEntity | null> {
    return await this.post("/bff/season/md-map/sku/scratch", dto);
  }

  static async createMdMapOrderProductSkusFromScratch(
    dto: dto.UpsertOrderSkuFromExistingOrderProductDto
  ): Promise<UpsertOrderSkuFromExistingOrderProductEntity | null> {
    return await this.post("/bff/season/md-map/order-product/order-sku", dto);
  }

  static async updateMdMapSku(
    dto: dto.CreateOrderSkuFromExistingSkuInMdMapDto
  ): Promise<SkuWithOrderSkusEntity | null> {
    return await this.post("/bff/season/md-map/sku/existing", dto);
  }

  static async getNewJanCodes(num: number): Promise<string[] | null> {
    return await this.get("/bff/season/md-map/jan-code-logic/news", { number: num });
  }

  // Productの物理削除
  static async deleteMdMapProduct(id: string): Promise<Product | null> {
    return await this.delete(`/bff/season/md-map/product/${id}`, {});
  }

  // Skuの物理削除
  static async deleteMdMapSku(id: string): Promise<Sku | null> {
    return await this.delete(`/bff/season/md-map/sku/${id}`, {});
  }

  static async deleteMdMapOrderProduct(id: number): Promise<OrderProduct | null> {
    return await this.delete(`/bff/season/md-map/order-product/${id}`, {});
  }

  static async deleteMdMapOrderSku(id: number): Promise<OrderSku | null> {
    return await this.delete(`/bff/season/md-map/order-sku/${id}`, {});
  }

  static async updateSkusCost(dto: dto.UpdateSkuCostDto) {
    return await this.patch("/bff/season/md-map/sku/cost", dto);
  }

  // 外部API連携
  static async futureshopAuthorize(dto: dto.CreateFutureshopIntegrationDto): Promise<AuthResult | null> {
    return await this.post("/integration/futureshop/authorize", dto);
  }
  static async updateFutureshopIntegrationLogic(
    id: number,
    dto: dto.UpdateFutureshopIntegrationDto
  ): Promise<FutureshopIntegration | null> {
    return await this.post(`/integration/futureshop/update/${id}`, dto);
  }

  static async makeshopAuthorize(dto: dto.CreateMakeshopIntegrationDto): Promise<AuthResult | null> {
    return await this.post("/integration/makeshop/authorize", dto);
  }
  static async updateMakeshopIntegrationLogic(
    id: number,
    dto: dto.UpdateMakeshopIntegrationDto
  ): Promise<MakeshopIntegration | null> {
    return await this.post(`/integration/makeshop/update/${id}`, dto);
  }

  static async nextEngineAuthorize(dto: dto.NextEngineAuthorizeDto): Promise<AuthResult | null> {
    return await this.post("/integration/next-engine/authorize", dto);
  }
  static async updateNextEngineIntegrationLogic(
    id: number,
    dto: dto.UpdateNextEngineIntegrationDto
  ): Promise<NextEngineIntegration | null> {
    return await this.post(`/integration/next-engine/update/${id}`, dto);
  }

  static async baseAuthorize(dto: dto.CreateBaseIntegrationDto): Promise<AuthResult | null> {
    return await this.post("/integration/base/authorize", dto);
  }
  static async updateBaseIntegrationLogic(
    id: number,
    dto: dto.UpdateBaseIntegrationDto
  ): Promise<BaseIntegration | null> {
    return await this.post(`/integration/base/update/${id}`, dto);
  }
  static async logizardAuthorize(dto: dto.LogizardAuthorizeDto): Promise<AuthResult | null> {
    return await this.post("/integration/logizard/authorize", dto);
  }
  static async smaregiAuthorize(dto: dto.CreateSmaregiIntegrationDto): Promise<AuthResult | null> {
    return await this.post("/integration/smaregi/authorize", dto);
  }
  static async shopifyAuthorize(dto: dto.ShopifyAuthorizeDto): Promise<AuthResult | null> {
    return await this.post("/integration/shopify/authorize", dto);
  }

  static async removeExcludedStoreId(storeId: string) {
    return await this.patch(`/alert-setting/remove/${storeId}`, {});
  }

  static async addExcludedStoreId(storeId: string) {
    return await this.patch(`/alert-setting/add/${storeId}`, {});
  }

  static async listShopifyCustomizations(): Promise<ShopifyCustomization[] | null> {
    return await this.get("/shopify-customization", {});
  }
  static async createShopifyCustomization(
    dto: dto.CreateShopifyCustomizationDto
  ): Promise<ShopifyCustomization | null> {
    return await this.post("/shopify-customization", dto);
  }
  static async updateShopifyCustomization(
    id: number,
    dto: dto.UpdateShopifyCustomizationDto
  ): Promise<ShopifyCustomization | null> {
    return await this.patch(`/shopify-customization/${id}`, dto);
  }
  static async deleteShopifyCustomization(id: number): Promise<ShopifyCustomization | null> {
    return await this.delete(`/shopify-customization/${id}`, {});
  }

  // 外部API連携解除
  static async deintegrateSmaregi(id: number): Promise<SmaregiIntegration | null> {
    return await this.delete(`/smaregi-integration/${id}`, {});
  }
  static async deintegrateFutureshop(id: number): Promise<FutureshopIntegration | null> {
    return await this.delete(`/futureshop-integration/${id}`, {});
  }
  static async deintegrateBase(id: number): Promise<BaseIntegration | null> {
    return await this.delete(`/base-integration/${id}`, {});
  }
  static async deintegrateLogizard(id: number): Promise<LogizardIntegration | null> {
    return await this.delete(`/logizard-integration/${id}`, {});
  }
  static async deintegrateShopifyIntegration(id: number): Promise<ShopifyIntegration | null> {
    return await this.delete(`/shopify-integration/${id}`, []);
  }
  static async deintegrateMakeshop(id: number): Promise<MakeshopIntegration | null> {
    return await this.delete(`/makeshop-integration/${id}`, {});
  }
  static async deintegrateNextEngine(id: number): Promise<NextEngineIntegration | null> {
    return await this.delete(`/next-engine-integration/${id}`, {});
  }

  // Integrationテーブル
  static async updateCustomIntegration(
    id: string,
    dto: dto.UpdateCustomIntegrationDto
  ): Promise<CustomIntegration | null> {
    return await this.patch(`/custom-integration/${id}`, dto);
  }
  static async updateLogizardIntegration(
    id: number,
    dto: dto.UpdateLogizardIntegrationDto
  ): Promise<LogizardIntegration | null> {
    return await this.patch(`/logizard-integration/${id}`, dto);
  }
  static async updateSmaregiIntegration(
    id: number,
    dto: dto.UpdateSmaregiIntegrationDto
  ): Promise<SmaregiIntegration | null> {
    return await this.patch(`/smaregi-integration/${id}`, dto);
  }
  static async updateFutureshopIntegration(
    id: number,
    dto: dto.UpdateFutureshopIntegrationDto
  ): Promise<FutureshopIntegration | null> {
    return await this.patch(`/futureshop-integration/${id}`, dto);
  }
  static async updateBaseIntegration(id: number, dto: dto.UpdateBaseIntegrationDto): Promise<BaseIntegration | null> {
    return await this.patch(`/base-integration/${id}`, dto);
  }
  static async updateShopifyIntegration(
    id: number,
    dto: dto.UpdateShopifyIntegrationDto
  ): Promise<ShopifyIntegration | null> {
    return await this.patch(`/shopify-integration/${id}`, dto);
  }
  static async updateMakeshopIntegration(
    id: number,
    dto: dto.UpdateMakeshopIntegrationDto
  ): Promise<MakeshopIntegration | null> {
    return await this.patch(`/makeshop-integration/${id}`, dto);
  }
  static async updateNextEngineIntegration(
    id: number,
    dto: dto.UpdateMakeshopIntegrationDto
  ): Promise<MakeshopIntegration | null> {
    return await this.patch(`/next-engine-integration/${id}`, dto);
  }
  static async listSmaregiIntegration(): Promise<SmaregiIntegration[] | null> {
    return await this.get(`/smaregi-integration`, {});
  }
  static async listFutureshopIntegration(): Promise<FutureshopIntegration[] | null> {
    return await this.get(`/futureshop-integration`, {});
  }
  static async listCustomIntegration(): Promise<CustomIntegration[] | null> {
    return await this.get(`/custom-integration`, {});
  }
  static async createCustomIntegration(dto: dto.CreateCustomIntegrationDto): Promise<CustomIntegration | null> {
    return await this.post(`/custom-integration`, dto);
  }
  static async deleteCustomIntegration(id: string): Promise<CustomIntegration | null> {
    return await this.delete(`/custom-integration/${id}`, {});
  }


  static async getSmaregiStores(contractId: string): Promise<SmaregiStore[] | null> {
    return await this.get("/integration/smaregi/stores", {
      contractId: contractId,
    });
  }

  static async getShopifyStores(): Promise<ShopifyStoreEntity[] | null> {
    return await this.get("/integration/shopify/stores", {});
  }

  static async getShopifyStoreStatuses(): Promise<ShopifyStoreEntity[] | null> {
    return await this.get("/integration/shopify/stores", {});
  }

  static async updateShopifyStoreStatus(dto: dto.ShopifyStoreDto): Promise<StatusResultEntity | null> {
    return await this.post("/integration/shopify/stores/update", dto);
  }

  static async listSmaregiOption(): Promise<SmaregiOption[] | null> {
    return await this.get("/smaregi-option", {});
  }

  static async createSmaregiOption(dto: dto.CreateSmaregiOptionDto): Promise<SmaregiOption | null> {
    return await this.post("/smaregi-option", dto);
  }

  static async updateSmaregiOption(id: string, dto: dto.UpdateSmaregiOptionDto): Promise<SmaregiOption | null> {
    return await this.patch(`/smaregi-option/${id}`, dto);
  }

  static async deleteSmaregiOption(id: string): Promise<SmaregiOption | null> {
    return await this.delete(`/smaregi-option/${id}`, {});
  }

  static async getSmaregiFreeLabel(contractId: string): Promise<SmaregiFreeLabel[] | null> {
    return await this.post(`/integration/smaregi/free-labels?contractId=${contractId}`, {});
  }

  static async listShopifyOption(): Promise<ShopifyOption[] | null> {
    return await this.get("/shopify-option", {});
  }

  static async createShopifyOption(dto: dto.CreateShopifyOptionDto): Promise<ShopifyOption | null> {
    return await this.post("/shopify-option", dto);
  }

  static async updateShopifyOption(id: string, dto: dto.UpdateShopifyOptionDto): Promise<ShopifyOption | null> {
    return await this.patch(`/shopify-option/${id}`, dto);
  }

  static async deleteShopifyOption(id: string): Promise<ShopifyOption | null> {
    return await this.delete(`/shopify-option/${id}`, {});
  }

  // デリバリーマップ
  static async getSalesInfo(yearMonth: string): Promise<DeliveryMapEntity | null> {
    return await this.get("/bff/delivery-map/product", {
      yearMonth,
    });
  }

  // 売上仕入計画
  static async getSalesBuyingPlanTotalEntity(seasonId: number): Promise<SalesBuyingPlanTotalEntity[] | null> {
    return await this.get("/bff/season/sales-buying-plan/total", {
      seasonId,
    });
  }
  static async getSalesBuyingPlanBrandCategoryEntity(
    seasonId: number,
    brandCategoryId: string,
    brandId: number,
    dto: dto.BudgetTotalDto[]
  ): Promise<SalesBuyingPlanBrandCategoryEntity | null> {
    return await this.post(
      `/bff/season/sales-buying-plan/by-brand-category?seasonId=${seasonId}&brandCategoryId=${brandCategoryId}&brandId=${brandId}`,
      dto
    );
  }

  static async getSalesBuyingPlanSaleRate(seasonId: number): Promise<SalesBuyingPlanMonthBudgetTotalRateEntity | null> {
    return await this.get(`/bff/season/sales-buying-plan/by-brand-category/sale?seasonId=${seasonId}`, {});
  }

  static async getSalesBuyingPlanBuyingRate(
    seasonId: number
  ): Promise<SalesBuyingPlanMonthBudgetTotalRateEntity | null> {
    return await this.get(`/bff/season/sales-buying-plan/by-brand-category/buying?seasonId=${seasonId}`, {});
  }

  static async updateSalesBuyingPlanTotalDto(dto: dto.UpdateSalesBuyingPlanTotalDto) {
    return await this.patch("/bff/season/sales-buying-plan/total", dto);
  }
  static async updateSalesBuyingPlanBudgetBrandCategoryDto(dto: dto.UpdateSalesBuyingPlanBrandCategoryDto) {
    return await this.patch("/bff/season/sales-buying-plan/by-brand-category", dto);
  }

  // シーズン店舗売上表
  static async getStoreSeasonPlanTotalEntity(start: string, end: string): Promise<StoreSeasonPlanTotalEntity | null> {
    return await this.get("/bff/store-season-plan/total", { start, end });
  }
  static async getStoreSeasonPlanSeasonTotalEntity(
    start: string,
    end: string
  ): Promise<StoreSeasonPlanSeasonTotalEntity[] | null> {
    return await this.get("/bff/store-season-plan/store-season/total", { start, end });
  }
  static async getStoreSeasonPlanStoreEntity(
    seasonId: number,
    start: string,
    end: string,
    dto: dto.StoreSeasonSaleBudgetInfoDto | null
  ): Promise<StoreSeasonPlanStoreEntity[] | null> {
    return await this.post(`/bff/store-season-plan/season/${seasonId}?start=${start}&end=${end}`, { dto });
  }
  // TODO: まだ必要か確認
  static async updateStoreSeasonPlan(dto: dto.StoreSeasonPlanDto) {
    return await this.post("/bff/store-season-plan", dto);
  }

  // 発注一覧
  static async getOrderInstruction(id: string): Promise<OrderInstruction | null> {
    return await this.get(`/order-instruction/${id}`, {});
  }
  static async getOrderInstructions(
    dto: dto.OrderInstructionSearchDto
  ): Promise<OrderInstructionSearchResultEntity[] | null> {
    return await this.post("/bff/order/instruction/search", dto);
  }
  static async listOrderInstructionProducts(id: string): Promise<OrderInstructionDetailEntity[] | null> {
    return await this.get(`/bff/order/instruction/detail/${id}`, {});
  }
  static async getOrderProducts(dto: dto.OrderProductSearchDto): Promise<OrderProductSearchResultEntity[] | null> {
    return await this.post("/bff/order/product/search", dto);
  }
  static async downloadOrderInstruction(orderInstructionId: string) {
    return await this.get(`/bff/order/instruction/file/${orderInstructionId}`, {});
  }
  static async createOrderInstruction(dto: dto.CreateOrderInstructionDto) {
    return await this.post(`/order-instruction`, dto);
  }

  // 予算傾斜の更新導線
  static async getBudgetWeekRateList(year: number): Promise<BudgetWeekRate[] | null> {
    return await this.get(`/budget-week-rate/year/${year}`, {});
  }

  static async updateBudgetWeekRateList(dto: dto.UpdateBudgetWeekRateDto[]): Promise<BudgetWeekRate[] | null> {
    return await this.patch(`/budget-week-rate/year`, dto);
  }

  // 店舗別日別売上予算編集画面
  static async getDailyStoreBudgets(yearMonth: string, storeId: string): Promise<DailyStoreBudgetEntity | null> {
    return await this.get("/bff/daily-store-budget", { yearMonth: yearMonth, storeId: storeId });
  }

  static async updateDailyStoreBudgets(dto: dto.DailyStoreBudgetDto): Promise<DailyStoreBudgetEntity | null> {
    return await this.patch("/bff/daily-store-budget", dto);
  }

  // 画像アップロード画面
  static async checkImages(dto: dto.ImageUploadDto): Promise<ImageCheckResultEntity | null> {
    return await this.post("/image/check", dto);
  }

  static async uploadImages(dto: dto.ImageUploadDto) {
    return await this.post("/image/upload", dto);
  }

  // グループリスト
  static async getGroupListGraph(
    groupBy: string,
    dto: dto.GroupListGraphInputDto
  ): Promise<GroupListGraphItemEntity[] | null> {
    return await this.post(`/bff/group-list/graph/${groupBy}`, dto);
  }

  // AdminUser
  static async getAdminUser(id: string): Promise<AdminUser | null> {
    return await this.get(`/admin-user/${id}`, {});
  }
  static async listAdminUsers(): Promise<AdminUser[] | null> {
    return await this.get("/admin-user", {});
  }
  static async updateAdminUser(id: string, dto: dto.UpdateAdminUserDto): Promise<AdminUser | null> {
    return await this.patch(`/admin-user/${id}`, dto);
  }
  static async createAdminUser(dto: dto.CreateAdminUserDto): Promise<AdminUser | null> {
    return await this.post("/admin-user", dto);
  }
  static async deleteAdminUser(id: string): Promise<AdminUser | null> {
    return await this.delete(`/admin-user/${id}`, {});
  }

  // AdminUserMaster
  static async getAdminUserMaster(id: number): Promise<AdminUserMaster | null> {
    return await this.get(`/admin-user-master/${id}`, {});
  }
  static async listAdminUserMasters(): Promise<AdminUserMaster[] | null> {
    return await this.get("/admin-user-master", {});
  }
  static async updateAdminUserMaster(id: number, dto: dto.UpdateAdminUserMasterDto): Promise<AdminUserMaster | null> {
    return await this.patch(`/admin-user-master/${id}`, dto);
  }
  static async createAdminUserMaster(dto: dto.CreateAdminUserMasterDto): Promise<AdminUserMaster | null> {
    return await this.post("/admin-user-master", dto);
  }
  static async deleteAdminUserMaster(id: string): Promise<AdminUserMaster | null> {
    return await this.delete(`/admin-user-master/${id}`, {});
  }

  // Product Custom Value
  static async getProductCustomValue(id: string): Promise<ProductCustomValue | null> {
    return await this.get(`/product-custom-value/${id}`, {});
  }
  static async listProductCustomValue(): Promise<ProductCustomValue[] | null> {
    return await this.get("/product-custom-value", {});
  }
  static async updateProductCustomValue(id: string, dto: dto.UpdateProductCustomValueDto): Promise<ProductCustomValue | null> {
    return await this.patch(`/product-custom-value/${id}`, dto);
  }
  static async createProductCustomValue(dto: dto.CreateProductCustomValueDto): Promise<ProductCustomValue | null> {
    return await this.post("/product-custom-value", dto);
  }
  static async deleteProductCustomValue(id: string): Promise<ProductCustomValue | null> {
    return await this.delete(`/product-custom-value/${id}`, {});
  }

  // Sku Custom Value
  static async getSkuCustomValue(id: string): Promise<SkuCustomValue | null> {
    return await this.get(`/sku-custom-value/${id}`, {});
  }
  static async listSkuCustomValues(): Promise<SkuCustomValue[] | null> {
    return await this.get("/sku-custom-value", {});
  }
  static async updateSkuCustomValue(id: string, dto: dto.UpdateSkuCustomValueDto): Promise<SkuCustomValue | null> {
    return await this.patch(`/sku-custom-value/${id}`, dto);
  }
  static async createSkuCustomValue(dto: dto.CreateSkuCustomValueDto): Promise<SkuCustomValue | null> {
    return await this.post("/sku-custom-value", dto);
  }
  static async deleteSkuCustomValue(id: string): Promise<SkuCustomValue | null> {
    return await this.delete(`/sku-custom-value/${id}`, {});
  }

  // Product Custom Value Setting
  static async getProductCustomValueSetting(id: string): Promise<ProductCustomValueSetting | null> {
    return await this.get(`/product-custom-value-setting/${id}`, {});
  }
  static async listProductCustomValueSettings(): Promise<ProductCustomValueSetting[] | null> {
    return await this.get("/product-custom-value-setting", {});
  }
  static async updateProductCustomValueSetting(id: string, dto: dto.UpdateProductCustomValueSettingDto): Promise<ProductCustomValueSetting | null> {
    return await this.patch(`/product-custom-value-setting/${id}`, dto);
  }
  static async createProductCustomValueSetting(dto: dto.CreateProductCustomValueSettingDto): Promise<ProductCustomValueSetting | null> {
    return await this.post("/product-custom-value-setting", dto);
  }
  static async deleteProductCustomValueSetting(id: string): Promise<ProductCustomValueSetting | null> {
    return await this.delete(`/product-custom-value-setting/${id}`, {});
  }

  // Sku Custom Value Setting
  static async getSkuCustomValueSetting(id: string): Promise<SkuCustomValueSetting | null> {
    return await this.get(`/sku-custom-value-setting/${id}`, {});
  }
  static async listSkuCustomValueSettings(): Promise<SkuCustomValueSetting[] | null> {
    return await this.get("/sku-custom-value-setting", {});
  }
  static async updateSkuCustomValueSetting(id: string, dto: dto.UpdateSkuCustomValueSettingDto): Promise<SkuCustomValueSetting | null> {
    return await this.patch(`/sku-custom-value-setting/${id}`, dto);
  }
  static async createSkuCustomValueSetting(dto: dto.CreateSkuCustomValueSettingDto): Promise<SkuCustomValueSetting | null> {
    return await this.post("/sku-custom-value-setting", dto);
  }
  static async deleteSkuCustomValueSetting(id: string): Promise<SkuCustomValueSetting | null> {
    return await this.delete(`/sku-custom-value-setting/${id}`, {});
  }

  // Product images
  static async getProductMainImages(dto: dto.GetMainImagesDto): Promise<GetMainImagesEntity | null> {
    return await this.post("/image/main", dto);
  }
  static async getProductDetailImages(productId: string): Promise<GetDetailImagesEntity | null> {
    return await this.get(`/image/detail/${productId}`, {});
  }

  // Sku images
  static async getSkuMainImages(dto: dto.GetSkuMainImagesDto): Promise<S3KeySkuMainEntity[] | null> {
    return await this.post("/image/sku/main", dto);
  }

  // ActionList
  static async listAllActions(): Promise<Action[] | null> {
    return await this.get("/action", {});
  }
  static async getAction(id: string): Promise<Action | null> {
    return await this.get(`/action/${id}`, {});
  }
  static async listActionList(dto: dto.ActionListSearchFilterDto): Promise<ActionListSearchResultEntity[] | null> {
    return await this.post("/bff/action-list/search", dto);
  }
  static async createAction(dto: dto.CreateActionDto): Promise<Action | null> {
    return await this.post("/action", dto);
  }
  static async updateAction(id: string, dto: dto.UpdateActionDto): Promise<Action | null> {
    return await this.patch(`/action/${id}`, dto);
  }
  static async listCustomActionMasters(): Promise<CustomActionMaster[] | null> {
    return await this.get("/custom-action-master", {});
  }
  static async createCustomActionMaster(dto: dto.CreateCustomActionMasterDto): Promise<CustomActionMaster | null> {
    return await this.post("/custom-action-master", dto);
  }
  static async deleteAction(id: string): Promise<Action | null> {
    return await this.delete(`/action/${id}`, {});
  }
  static async updatePriceInActionList(id: string) {
    return await this.patch(`/bff/action-list/price/${id}`, {});
  }
  static async updateSaleActionDetail(
    id: string,
    dto: dto.UpdateSaleActionDetailDto
  ): Promise<SaleActionDetail | null> {
    return await this.patch(`/sale-action-detail/${id}`, dto);
  }
  static async updateInterstoreTransferDetail(
    id: string,
    dto: dto.UpdateInterstoreTransferActionDetailDto
  ): Promise<InterstoreTransferActionDetail | null> {
    return await this.patch(`/interstore-transfer-action-detail/${id}`, dto);
  }
  static async listSaleActionDetails(): Promise<SaleActionDetail[] | null> {
    return await this.get("/sale-action-detail", {});
  }
  static async listProductsInSaleAction(id: string): Promise<ActionListProductSaleActionDetailEntity[] | null> {
    return await this.get(`/bff/action-list/product/${id}`, {});
  }
  static async listProductsSkusInCustomAction(id: string): Promise<ActionListCustomActionEntity[] | null> {
    return await this.get(`/bff/action-list/custom/${id}`, {});
  }
  static async listSkusInStockAction(id: string): Promise<ActionListSkuDetailStockActionEntity[] | null> {
    return await this.get(`/bff/action-list/sku/stock/${id}`, {});
  }
  static async listSkusInInterstoreTransferAction(id: string): Promise<ActionListInterstoreTransferActionDetailEntity[] | null> {
    return await this.get(`/bff/action-list/sku/interstore-transfer/${id}`, {});
  }
  static async deleteSaleActionDetail(id: string): Promise<SaleActionDetail | null> {
    return await this.delete(`/sale-action-detail/${id}`, {});
  }
  static async getCustomActionDetail(id: string): Promise<CustomActionDetail | null> {
    return await this.get(`/custom-action-detail/${id}`, {});
  }
  static async deleteCustomActionDetail(id: string): Promise<CustomActionDetail | null> {
    return await this.delete(`/custom-action-detail/${id}`, {});
  }
  static async deleteInterstoreTransferDetail(id: string): Promise<InterstoreTransferActionDetail | null> {
    return await this.delete(`/interstore-transfer-action-detail/${id}`, {});
  }

  // ActionRequest
  static async listActionRequset(
    dto: dto.ActionRequestSearchFilterDto
  ): Promise<ActionRequestSearchResultEntity[] | null> {
    return await this.post("/bff/action-request/search", dto);
  }
  static async getStockActionDetail(id: string): Promise<StockActionDetail | null> {
    return await this.get(`/stock-action-detail/${id}`, {});
  }
  static async deleteStockActionDetail(id: string): Promise<StockActionDetail | null> {
    return await this.delete(`/stock-action-detail/${id}`, {});
  }
  static async createStockActionDetail(dto: dto.CreateStockActionDetailDto): Promise<StockActionDetail | null> {
    return await this.post("/stock-action-detail", dto);
  }
  static async createActionFromRequests(dto: dto.ActionCreateDto): Promise<null> {
    return await this.patch(`/bff/action-request/create-action`, dto);
  }
  static async updateStockActionDetail(
    id: string,
    dto: dto.UpdateStockActionDetailDto
  ): Promise<StockActionDetail | null> {
    return await this.patch(`/stock-action-detail/${id}`, dto);
  }

  // CustomAction
  static async updateCustomActionMaster(
    id: string,
    dto: dto.UpdateCustomActionMasterDto
  ): Promise<CustomActionMaster | null> {
    return await this.patch(`/custom-action-master/${id}`, dto);
  }
  static async deleteCustomActionMaster(id: string): Promise<CustomActionMaster | null> {
    return await this.delete(`/custom-action-master/${id}`, {});
  }
}
